import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ExternalLink from 'shared/app/components/external-link';

import { commonQuestionsUrlSelector } from '../../state/selectors';
import messages from './messages';

const Questions = () => {
  const commonQuestionsUrl = useSelector(commonQuestionsUrlSelector);

  return (
    <ContentColumn className="sb-global-gutters--maxWidthLayout" size="xl">
      <div className="md-offset2of12 md-size6of12">
        <Heading className="text-semibold text-lg pb3" tagName="h2">
          <FormattedMessage {...messages.questionsHeader} />
        </Heading>
        <p className="pb3">
          <FormattedMessage
            {...messages.questionsDescription}
            values={{
              link: (content) => (
                <ExternalLink href={commonQuestionsUrl}>{content}</ExternalLink>
              ),
            }}
          />
        </p>
      </div>
    </ContentColumn>
  );
};

export default Questions;
