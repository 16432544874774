import React from 'react';

import OfferOptIn from './index';
import messages from './messages';

const ActiveChoice = () => {
  return (
    <OfferOptIn
      body1Text={messages.activeChoiceBody1}
      body2Text={messages.activeBody2}
      headingText={messages.activeHeader}
      imgHeight={256}
      imgPath="/rewards/offers/opt-in-active.svg"
      imgWidth={256}
    />
  );
};

export default ActiveChoice;
