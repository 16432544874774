import Rewards from './components/rewards-page';
import ActiveExternal from './components/offers-opt-in/active-external';
import ActiveChoice from './components/offers-opt-in/active-choice';
import ActiveMultiplier from './components/offers-opt-in/active-multiplier';
import Expired from './components/offers-opt-in/expired';
import ExpiredMultiplier from './components/offers-opt-in/expired-multiplier';
import OfferOptInError from './components/offers-opt-in/offer-opt-in-error';
import OptInSuccess from './components/offers-opt-in/success';
import OptInSuccessMultiplier from './components/offers-opt-in/success-multiplier';
import PromoCodes from './components/promo-codes';
import PartnershipOverview from './components/partnerships/index';
import LinkSuccess from './components/partnerships/marriott-result-pages/link-success';
import UnlinkSuccess from './components/partnerships/marriott-result-pages/unlink-success';
import MarriottError from './components/partnerships/marriott-result-pages/error';
import NeverLinked from './components/partnerships/marriott-result-pages/never-linked';
import AlreadyLinked from './components/partnerships/marriott-result-pages/already-linked';
import RedirectPage from './components/partnerships/redirect';

import rootReducer from './state/root-reducer';

const defaultRewardsRoutes = {
  '/rewards': {
    fullPageComponent: Rewards,
    fullWidthBottomDrawer: true,
  },
  '/rewards/promo/opt-in/active-external': {
    fullPageComponent: ActiveExternal,
  },
  '/rewards/promo/opt-in/active-choice': {
    fullPageComponent: ActiveChoice,
  },
  '/rewards/promo/opt-in/active-multiplier': {
    fullPageComponent: ActiveMultiplier,
  },
  '/rewards/promo/opt-in/error': {
    fullPageComponent: OfferOptInError,
  },
  '/rewards/promo/opt-in/expired': {
    fullPageComponent: Expired,
  },
  '/rewards/promo/opt-in/expired-multiplier': {
    fullPageComponent: ExpiredMultiplier,
  },
  '/rewards/promo/opt-in/success': {
    fullPageComponent: OptInSuccess,
  },
  '/rewards/promo/opt-in/success-multiplier': {
    fullPageComponent: OptInSuccessMultiplier,
  },
  '/rewards/promo/redeem': {
    fullPageComponent: PromoCodes,
    authRequired: true,
  },
};

const marriottRoutes = {
  '/rewards/partnerships/marriottbonvoy': {
    fullPageComponent: PartnershipOverview,
  },
  '/rewards/partnerships/marriottbonvoy/success': {
    fullPageComponent: LinkSuccess,
  },
  '/rewards/partnerships/marriottbonvoy/unlinked': {
    fullPageComponent: UnlinkSuccess,
  },
  '/rewards/partnerships/marriottbonvoy/error': {
    fullPageComponent: MarriottError,
  },
  '/rewards/partnerships/marriottbonvoy/not-linked': {
    fullPageComponent: NeverLinked,
  },
  '/rewards/partnerships/marriottbonvoy/already-linked': {
    fullPageComponent: AlreadyLinked,
  },
  '/rewards/partnerships/marriottbonvoy/redirect': {
    fullPageComponent: RedirectPage,
    hideGlobalNav: true,
    hideGlobalFooter: true,
  },
};

const routes = (config) =>
  config.enableMarriott
    ? { ...defaultRewardsRoutes, ...marriottRoutes }
    : { ...defaultRewardsRoutes };

export default {
  name: 'rewards',
  reducer: rootReducer,
  routes,
};
