import { useIntl } from 'react-intl';
import messages from './messages';

export const getThreeStepAltText = (step) => {
  const { formatMessage } = useIntl();
  return formatMessage(messages.stepsThumbnailAlt, { stepNumber: step });
};

export const splitFooterIntoColumns = ({ terms, indexToSplit }) => {
  const termsIndexBreak = indexToSplit ?? Math.ceil(terms.length / 2);

  const leftColumn = terms.slice(0, termsIndexBreak);
  const rightColumn = terms.slice(
    termsIndexBreak,
    termsIndexBreak + terms.length
  );

  return { leftColumn, rightColumn };
};
