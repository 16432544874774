import { defineMessages } from 'react-intl';

export default defineMessages({
  mainBody: {
    id: 'rewards.terms.mainBody',
    description: 'Body for terms section',
    defaultMessage: 'At participating stores. Restrictions apply.',
  },
  deltaTerms: {
    id: 'rewards.terms.deltaTerms',
    description: 'Rewards delta terms description',
    defaultMessage:
      '{sup}Excludes taxes and gratuities. At participating stores. Some restrictions apply. Flights purchased close to departure may not earn double Stars. Stars and miles may not be earned on purchases of alcohol, Starbucks Cards and Starbucks Card reloads. For details, visit <link>deltastarbucks.com/terms</link>.',
  },
  bankOfAmericaTerms: {
    id: 'rewards.terms.bankOfAmericaTerms',
    description: 'Rewards bank of america description',
    defaultMessage:
      '{sup}At participating stores only. Some restrictions apply. Linked Card members will earn 2% Cash Back on the full purchase price of every Qualifying Purchase. Extra Star offer excludes taxes and tips. Stars may not be earned on purchases of alcohol or on reloads of Starbucks Cards that are not registered. For details, visit {link}. Bank of America, N.A. Member FDIC.',
  },
  marriottTerms: {
    id: 'rewards.terms.marriottTerms',
    description: 'Rewards Marriott Terms Description',
    defaultMessage:
      '{sup}Individuals must link loyalty program accounts to participate in offer. Members with linked accounts will earn: (a) double Starbucks Rewards Stars on Qualifying Starbucks Transactions during Eligible Stays at Participating Marriott Bonvoy Hotels, excluding stays at Homes & Villas by Marriott Bonvoy and The Ritz-Carlton Yacht Collection, and (b) earn 100 Marriott Bonvoy Points upon completion of 3 Qualifying Starbucks Transactions during Marriott Bonvoy Week. Qualifying Starbucks Transactions must be made at U.S. participating Starbucks stores and exclude purchases of alcoholic beverages, Starbucks Card eGifts and physical Starbucks gift. To earn Double Stars, the applicable stay must qualify as an Eligible Stay at a Participating Marriott Bonvoy® Hotel under offer terms and conditions. Other restrictions and exclusions apply. For full offer terms and conditions, visit <link>Starbucks.com/MarriottBonvoy</link>. Marriott Bonvoy® and its affiliated brands and hotels are the registered trademarks and/or service marks of Marriott International, Inc. and its affiliates.  Used under license from Marriott International, Inc.',
  },
  aeroplanTermsDescription: {
    id: 'rewards.terms.aeroplanTermsDescription',
    description: 'Rewards question Aeroplan Terms Description',
    defaultMessage:
      '{sup}Full <link>Aeroplan Starbucks Offer</link> Terms and Conditions',
  },
  tdBankTermsDescription: {
    id: 'rewards.terms.tdBankTermsDescription',
    description: 'Rewards question TD Bank Terms Description',
    defaultMessage:
      '{sup}Full <link>TD Starbucks Offer</link> Terms and Conditions',
  },
  aeroplanTrademarkDescription: {
    id: 'rewards.terms.aeroplanTrademarkDescription',
    description: 'Rewards question Aeroplan Trademark Description',
    defaultMessage: '® Aeroplan is a registered trademark of Aeroplan Inc.',
  },
  airCanadaTrademarkDescription: {
    id: 'rewards.terms.airCanadaTrademarkDescription',
    description: 'Rewards question Air Canada Trademark Description',
    defaultMessage:
      '® The Air Canada maple leaf logo is a registered trademark of Air Canada, used under licence by Aeroplan Inc.',
  },
  tdBankTrademarkDescription: {
    id: 'rewards.terms.tdBankTrademarkDescription',
    description: 'Rewards question TD Bank Trademark Description',
    defaultMessage:
      '® The TD logo and other trademarks are the property of The Toronto-Dominion Bank.',
  },
  question1Header: {
    id: 'rewards.terms.question1Header',
    description: 'Earning stars section heading',
    defaultMessage: 'Earning Stars',
  },
  question1Description1: {
    id: 'rewards.terms.question1Description1',
    description: 'Earning stars section description 1',
    defaultMessage:
      'Stars cannot be earned on purchases of alcohol, Starbucks Cards or Starbucks Card reloads.',
  },
  question1Description2: {
    id: 'rewards.terms.question1Description2',
    description: 'Earning stars section description 2',
    defaultMessage:
      'Earn 1 Star per $1 spent when you scan your member barcode in the app, then pay with cash, credit/debit cards or mobile wallets at participating stores. You can also earn 1 Star per $1 spent when you link a payment method and pay directly through the app.',
  },
  question1Description3: {
    id: 'rewards.terms.question1Description3',
    description: 'Earning stars section description 3',
    defaultMessage:
      'Earn 2 Stars per $1 spent when you load funds and pay with your digital Starbucks Card in the app. You can also earn 2 Stars per $1 spent when you pay in-person at a participating store with your registered physical Starbucks Card or scan your member barcode in the app, and then use any physical Starbucks Card (regardless of whether it is registered) to complete the purchase.',
  },
  question2Header: {
    id: 'rewards.terms.question2Header',
    description: 'Question 2 section heading',
    defaultMessage: 'Redeeming Rewards',
  },
  question2Description: {
    id: 'rewards.terms.question2Description',
    description: 'Question 2 section description',
    defaultMessage:
      'Rewards cannot be redeemed for alcoholic beverages or multi-serve items. You pay the difference for any beverage customization over $1 and/or merchandise item over $20. Not all stores honor tiered Rewards. Select stores redeem 200 Stars for free food or drink items only.',
  },
  question3Header: {
    id: 'rewards.terms.question3Header',
    description: 'Question 3 section heading',
    defaultMessage: 'Benefits',
  },
  question3Description: {
    id: 'rewards.terms.question3Description',
    description: 'Question 3 section description',
    defaultMessage:
      'Free refills available during same in-store visit only. To qualify for the Birthday Reward, you must have made at least one Star-earning transaction.',
  },
  question4Header: {
    id: 'rewards.terms.question4Header',
    description: 'Question 4 section heading',
    defaultMessage: 'Terms of Use',
  },
  question4Description1: {
    id: 'rewards.terms.question4Description1',
    description: 'Question 4 section description 1',
    defaultMessage:
      'For full program details visit <link>starbucks.com/rewards/terms</link>.',
  },
  question4Description2: {
    id: 'rewards.terms.question4Description2',
    description: 'Question 4 section description 2',
    defaultMessage:
      'Starbucks® Rewards benefits are available at participating Starbucks stores. Not all stores have the ability to honor Rewards at this time. Visit the <link>Starbucks Store Locator</link> and search for locations honoring “Redeem Rewards”.',
  },
});
