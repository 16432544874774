import React from 'react';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import H1 from 'shared/app/components/h1';
import styles from './styles.cssm';

const verticalAlignMap = {
  bottom: 'items-end',
  middle: 'items-center',
  top: 'items-start',
};
const horizontalAlignMap = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

const HeroContent = ({
  verticalAlign,
  horizontalAlign,
  containerClassName = '',
  bodyClassName = '',
  textAlign,
  title,
  titleClassName,
  titleHeadingLevel = 'h1',
  body,
  cta1,
  cta1TagName,
  cta2,
  logoImage,
  logoImageWidth,
}) => {
  const HeadingTagName = titleHeadingLevel === 'h1' ? H1 : Heading;
  const alignmentClasses = `${verticalAlignMap[verticalAlign]} ${horizontalAlignMap[horizontalAlign]}`;
  const TagName = cta1TagName || Button;
  return (
    <div
      className={`flex size12of12 ${alignmentClasses} ${containerClassName}`}
    >
      <div
        className={`py5 text-${textAlign} ${styles.textBlock} sb-global-gutters`}
      >
        {logoImage && (
          <ImageLoader
            alt=""
            role="presentation"
            src={logoImage}
            width={logoImageWidth}
          />
        )}
        {title && (
          <HeadingTagName
            className={titleClassName}
            size="xl"
            tagName={titleHeadingLevel}
          >
            {title}
          </HeadingTagName>
        )}
        {body && <div className={`pt3 ${bodyClassName}`}>{body}</div>}
        {(cta1 || cta2) && (
          <div className="pt5">
            {cta1 && <TagName {...cta1} />}
            {cta2 && (
              <div className={`mt3 ${bodyClassName}`}>
                <Button color="black" visualStyle="textLink" {...cta2} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroContent;
