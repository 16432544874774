import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { isSmMaxViewportSelector } from 'shared/app/bundles/viewport';

import ThumbnailContentBlock from '../thumbnail-content-block';

import styles from './styles.cssm';

const ContentBlocks = ({
  isMobileViewport,
  content,
  cta,
  header,
  headingRef,
  titleHeadingLevel = 'h2',
  contentHeadingLevels = 'h3',
  MultiThumbnailsRowTagName = 'div',
  ThumbnailContentBlocksTagName,
}) => {
  const { heading, subHeading } = header;
  return (
    <ContentColumn className="sb-global-gutters--maxWidthLayout " size="xl">
      <div className="size12of12 md-offset2of12 md-size8of12 lg-offset3of12 lg-size6of12 text-center">
        {heading && (
          <Heading
            className="text-semibold"
            ref={headingRef}
            size="lg"
            tagName={titleHeadingLevel}
          >
            {heading}
          </Heading>
        )}
        {subHeading && <p className="text-center pt3">{subHeading}</p>}
        {cta && <div className="text-center align-center pt3">{cta}</div>}
      </div>
      <MultiThumbnailsRowTagName className={styles.multiThumbnailsRow}>
        {content.map((thumbnailContentBlock, idx) => (
          <ThumbnailContentBlock
            ThumbnailContentBlocksTagName={ThumbnailContentBlocksTagName}
            containerClasses={classNames(
              'flex size12of12 md-size4of12 md-pb0',
              {
                'flex-column': !isMobileViewport,
                'md-mr4': idx === 0,
                'md-mx4': idx === 1,
                'md-ml4': idx === 2,
                pb5: idx !== content.length - 1,
              }
            )}
            ctas={thumbnailContentBlock.ctas}
            description={thumbnailContentBlock.description}
            headingProps={{
              message: thumbnailContentBlock.heading,
              tagName: contentHeadingLevels,
            }}
            imageAlt={thumbnailContentBlock.imageAlt}
            imagePosition={isMobileViewport ? 'left' : 'top'}
            imageProps={thumbnailContentBlock.imageProps}
            imageUrl={thumbnailContentBlock.imageUrl}
            key={`thumbnailContentBlock-${idx}`}
            onImageClick={thumbnailContentBlock.onImageClick}
          />
        ))}
      </MultiThumbnailsRowTagName>
    </ContentColumn>
  );
};

ContentBlocks.propTypes = {
  header: PropTypes.shape({
    heading: PropTypes.node,
    subHeading: PropTypes.node,
  }),
  headingLevels: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  thumbnailCardsContent: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      headingProps: PropTypes.object,
      subHeading: PropTypes.node,
      description: PropTypes.node,
      cta: PropTypes.node,
    })
  ),
};

const select = (state) => ({
  isMobileViewport: isSmMaxViewportSelector(state),
});

export default connect(select)(ContentBlocks);
