import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successHeading: {
    id: 'rewards.promoCodes.successHeading',
    defaultMessage: 'Success!',
    description: 'Heading of the promo codes success page',
  },
  subHeading: {
    id: 'rewards.promoCodes.subHeading',
    defaultMessage:
      'Head to {accountLink} or {placeOrderLink} to redeem your available offers.',
    description: 'Sub heading of the promo codes success page',
  },
  successDescription: {
    id: 'rewards.promoCodes.successDescription',
    defaultMessage: '(It could take up to 24 hours for the Reward to show up.)',
    description: 'description of the promo codes success page',
  },
  errorGeneric: {
    id: 'rewards.promoCodes.errorGeneric',
    defaultMessage: "Looks like you're not eligible for this offer.",
    description: 'Subheading text for generic error state',
  },
  errorInvalid: {
    id: 'rewards.promoCodes.errorInvalid',
    defaultMessage:
      'Looks like this offer could not be validated. {lineBreak}Please try again.',
    description: 'Subheading text for martech error state',
  },
  errorExpired: {
    id: 'rewards.promoCodes.errorExpired',
    defaultMessage: 'Looks like this offer can no longer be redeemed.',
    description: 'Subheading text for an expired error state',
  },
  errorTryAgain: {
    id: 'rewards.promoCodes.errorTryAgain',
    defaultMessage:
      'Looks like you tried to redeem this offer too soon. {lineBreak}Try again later.',
    description:
      'Subheading text for error state when promo code is not yet valid.',
  },
  errorMarketingCardTitle: {
    id: 'rewards.promoCodes.errorMarketingCardTitle',
    defaultMessage: 'Get to free faster',
    description: 'Marketing card title during error state',
  },
  errorMarketingCardDescription: {
    id: 'rewards.promoCodes.errorMarketingCardDescription',
    defaultMessage:
      'Earn Stars with Bonus Star challenges, Double Star Days, and exciting games in the Starbucks app.',
    description: 'Marketing card body description during error state',
  },
  successMarketingCardTitle: {
    id: 'rewards.promoCodes.successMarketingCardTitle',
    defaultMessage: 'Get the app',
    description: 'Marketing card title during success state',
  },
  successMarketingCardDescription: {
    id: 'rewards.promoCodes.successMarketingCardDescription',
    defaultMessage:
      'Rack up the Stars with regular opportunities to get rewarded for what you love in the Starbucks app.',
    description: 'Marketing card body description during success state',
  },
  userAccountLink: {
    id: 'rewards.promoCodes.userAccountLink',
    defaultMessage: 'your account',
    description:
      'link that directs user to their account to redeem their stars',
  },
  placeOrderLink: {
    id: 'rewards.promoCodes.placeOrderLink',
    defaultMessage: 'place an order',
    description: 'link that directs user to the menu for MOP',
  },
});
export default messages;
