import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { rewardsMessages } from 'shared/app/messages/rewards';

import MarriottBanner from '../../shared/marriott-banner';

const MarriottResultPage = ({
  body,
  BodyTagName,
  ctaHref,
  ctaOnClick,
  ctaText,
  heading,
  HeadingTagName,
  imageAlt,
  imagePath,
}) => {
  const { formatMessage } = useIntl();
  const pageTitle = BodyTagName === 'h1' ? body : heading;
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {`${pageTitle}: ${formatMessage(rewardsMessages.rewardsPartnershipsMarriott)}`}
        </title>
      </Helmet>
      <MarriottBanner
        BodyTagName={BodyTagName}
        HeadingTagName={HeadingTagName}
        body={body}
        columnClassName="px0 py5"
        ctaHref={ctaHref}
        ctaOnClick={ctaOnClick}
        ctaText={ctaText}
        heading={heading}
        imageAlt={imageAlt}
        imagePath={imagePath}
      />
    </React.Fragment>
  );
};

export default MarriottResultPage;
