import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import {
  waysToPayHash,
  waysToPaySectionId,
  scrollSectionIntoView,
  NAV_BAR_HEIGHT_MOBILE,
  NAV_BAR_HEIGHT_DESKTOP,
} from '../../../utils';
import messages from './messages';

const LearnHowCTA = ({ isMobileViewport }) => {
  const offset = isMobileViewport
    ? NAV_BAR_HEIGHT_MOBILE
    : NAV_BAR_HEIGHT_DESKTOP;

  const onClick = () => {
    const elt = document.getElementById(waysToPaySectionId);
    scrollSectionIntoView(elt, offset);
  };

  return (
    <a className="color-greenStarbucks" href={waysToPayHash} onClick={onClick}>
      <FormattedMessage {...messages.learnHowCTA} />
    </a>
  );
};

const select = (state) => ({
  isMobileViewport: isMobileViewportSelector(state),
});

export default connect(select)(LearnHowCTA);
