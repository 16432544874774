import deltaSkymiles from './programs/delta-skymiles';
import bankOfAmerica from './programs/bank-of-america';
import aeroplan from './programs/aeroplan';
import tdBank from './programs/td-bank';
import marriott from './programs/marriott';

const allPrograms = [deltaSkymiles, bankOfAmerica, aeroplan, tdBank, marriott];

export const getContent = (loyaltyPrograms, formatMessage) => {
  const content = allPrograms
    .map((item) => {
      if (loyaltyPrograms.includes(item.displayName)) {
        return item(formatMessage);
      }
    })
    .filter((value) => value !== undefined);
  return content;
};
