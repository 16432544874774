import { composeApp } from 'shared/app/shell';
import GlobalNav from 'shared/app/components/dotcom-global-nav';
import userBundle from 'shared/app/bundles/user';
import viewportBundle from 'shared/app/bundles/viewport';
import rewards from './sub-app';

export default composeApp({
  apps: [rewards, userBundle, viewportBundle],
  nav: {
    globalNav: {
      component: GlobalNav,
      props: {
        useMaxWidthLayout: true,
      },
    },
    footerNav: {
      props: {
        useMaxWidthLayout: true,
      },
    },
  },
});
