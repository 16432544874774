import { imageUrl } from '../../../../utils';

import { defineMessages } from 'react-intl';

const funFreebieMessages = defineMessages({
  headingContent1: {
    id: 'rewards.benefits.slides.funFreebies.1.headingContent',
    description: 'fun freebies slide 1 heading',
    defaultMessage: 'Free food, drinks & more',
  },
  bodyContent1: {
    id: 'rewards.benefits.slides.funFreebies.1.bodyContent',
    description: 'fun freebies slide 1 body content',
    defaultMessage:
      'Redeem your Stars for favorites like a handcrafted cold brew, protein box or even a coffee tumbler.',
  },
  headingContent2: {
    id: 'rewards.benefits.slides.funFreebies.2.headingContent',
    description: 'fun freebies slide 2 heading',
    defaultMessage: 'Complimentary birthday treat',
  },
  bodyContent2: {
    id: 'rewards.benefits.slides.funFreebies.2.bodyContent',
    description: 'fun freebies slide 2 body content',
    defaultMessage:
      'Every year on your birthday get a free drink or food item of your choice.',
  },
  headingContent3: {
    id: 'rewards.benefits.slides.funFreebies.3.headingContent',
    description: 'fun freebies slide 3 heading',
    defaultMessage: 'Brewed coffee & tea refills on us',
  },
  bodyContent3: {
    id: 'rewards.benefits.slides.funFreebies.3.bodyContent',
    description: 'fun freebies slide 3 body content',
    defaultMessage:
      'More Caffè Verona® Blend, please. We’re happy to refill your hot cup while you’re in the store. Just ask.',
  },
});

export const getFunFreebiesSlideData = (formatMessage) => [
  {
    imageUrl: `${imageUrl}/rewards/benefits/1A.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(funFreebieMessages.headingContent1),
    bodyContent: formatMessage(funFreebieMessages.bodyContent1),
  },
  {
    imageUrl: `${imageUrl}/rewards/benefits/1B.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(funFreebieMessages.headingContent2),
    bodyContent: formatMessage(funFreebieMessages.bodyContent2),
  },
  {
    imageUrl: `${imageUrl}/rewards/benefits/1C.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(funFreebieMessages.headingContent3),
    bodyContent: formatMessage(funFreebieMessages.bodyContent3),
  },
];
