import { defineMessages } from 'react-intl';

const messages = defineMessages({
  aeroplanProgramDescription: {
    id: 'rewards.loyaltyPartnerships.aeroplan.content',
    description: 'Air Canada content',
    defaultMessage:
      '<link>Link your Aeroplan</link> and Starbucks® Rewards accounts to unlock exclusive offers and earn Aeroplan points when you reload funds in the Starbucks app.{aeroplanNote1}',
  },
  bankOfAmericaProgramDescription: {
    id: 'rewards.loyaltyPartnerships.bankOfAmerica.content',
    description: 'Bank of America content',
    defaultMessage:
      '<link>Link your Bank of America</link> eligible card and Starbucks® Rewards account to earn 2% Cash Back and Bonus Stars on qualifying Starbucks in-app purchases.{bankOfAmericaNote2}',
  },
  deltaSkymilesProgramDescription: {
    id: 'rewards.loyaltyPartnerships.deltaSkymiles.content',
    description: 'Delta Skymiles content',
    defaultMessage:
      '<link>Link your Delta SkyMiles®</link> and Starbucks® Rewards accounts to earn miles on Starbucks Card reloads of $25 or more, plus Double Stars on travel days.{deltaSkymilesNote1}',
  },
  tdBankProgramDescription: {
    id: 'rewards.loyaltyPartnerships.tdBank.content',
    description: 'TD Bank content',
    defaultMessage:
      '<link>Link your eligible TD Credit or Visa Debit Card</link> and Starbucks® Rewards account to earn 50% more Stars when you order in the Starbucks app at participating Starbucks locations.{tdBankNote2}',
  },
  marriottProgramDescription: {
    id: 'rewards.loyaltyPartnerships.marriott.content',
    description: 'Marriott content',
    defaultMessage:
      '<inlineLink>Link your Marriott Bonvoy®</inlineLink> and Starbucks® Rewards accounts to earn Double Stars during eligible stays, points during any Marriott Bonvoy Week and more.{marriottTerms}',
  },
});

export default messages;
