import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import ContentBlocks from '../../shared/content-blocks';

import {
  getFunFreebiesSlideData,
  getOrderAndPayAheadSlideData,
  getGetToFreeFasterSlideData,
} from './learn-more-slide-data/index';
import BenefitsSwipeDeck from './benefits-swipe-deck';

import messages from './messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { imageUrl } from '../../../utils';

const Benefits = () => {
  const { openModal } = useModalContext();
  const { formatMessage } = useIntl();

  const openSwipeDeck = ({ dialogLabel = '', slideData }) => {
    openModal({
      ariaLabel: dialogLabel,
      component: BenefitsSwipeDeck,
      componentProps: {
        slideData,
      },
    });
  };

  const learnMoreCTA = ({ buttonLabel, dialogLabel, slideData }) => (
    <Button
      className="my3 color-greenStarbucks"
      onClick={() => openSwipeDeck({ dialogLabel, slideData })}
      visualStyle="textLink"
    >
      {formatMessage(sharedCallsToAction.learnMore)}
      <span className="hiddenVisually">{buttonLabel}</span>
    </Button>
  );

  const funFreebiesSlideData = getFunFreebiesSlideData(formatMessage);
  const orderAndPayAheadSlideData = getOrderAndPayAheadSlideData(formatMessage);
  const getToFreeFasterSlideData = getGetToFreeFasterSlideData(formatMessage);

  const benefits = [
    {
      imageUrl: `${imageUrl}/rewards/benefits/1-fun-freebies.jpg`,
      imageAlt: '',
      imageProps: { role: 'presentation' },
      onImageClick: () =>
        openSwipeDeck({
          dialogLabel: formatMessage(messages.benefit1Heading),
          slideData: funFreebiesSlideData,
        }),
      heading: formatMessage(messages.benefit1Heading),
      description: formatMessage(messages.benefit1Description),
      ctas: learnMoreCTA({
        dialogLabel: formatMessage(messages.benefit1Heading),
        slideData: funFreebiesSlideData,
        buttonLabel: formatMessage(messages.benefit1CtaDescription),
      }),
    },
    {
      imageUrl: `${imageUrl}/rewards/benefits/2-order-and-pay-ahead.jpg`,
      imageAlt: '',
      imageProps: { role: 'presentation' },
      onImageClick: () =>
        openSwipeDeck({
          dialogLabel: formatMessage(messages.benefit2Heading),
          slideData: orderAndPayAheadSlideData,
        }),
      heading: formatMessage(messages.benefit2Heading),
      description: formatMessage(messages.benefit2Description),
      ctas: learnMoreCTA({
        dialogLabel: formatMessage(messages.benefit2Heading),
        slideData: orderAndPayAheadSlideData,
        buttonLabel: formatMessage(messages.benefit2CtaDescription),
      }),
    },
    {
      imageUrl: `${imageUrl}/rewards/benefits/3-get-to-free-faster.jpg`,
      imageAlt: '',
      imageProps: { role: 'presentation' },
      onImageClick: () =>
        openSwipeDeck({
          dialogLabel: formatMessage(messages.benefit3Heading),
          slideData: getToFreeFasterSlideData,
        }),
      heading: formatMessage(messages.benefit3Heading),
      description: formatMessage(messages.benefit3Description),
      ctas: learnMoreCTA({
        dialogLabel: formatMessage(messages.benefit3Heading),
        slideData: getToFreeFasterSlideData,
        buttonLabel: formatMessage(messages.benefit3CtaDescription),
      }),
    },
  ];

  return (
    <ContentBlocks
      content={benefits}
      header={{
        heading: formatMessage(messages.header),
        subHeading: formatMessage(messages.description),
      }}
    />
  );
};

export default Benefits;
