import React from 'react';
import { useSelector } from 'react-redux';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import { configSelector } from 'shared/app/shell';

import { LeftStars, RightStars } from './rewards-stars/rewards-stars';
import ContentHeading from './content-heading';
import ProgramContent from './program-content';
import JoinButton from './join-button';

import styles from './styles.cssm';

const LoyaltyPartnerships = () => {
  const config = useSelector(configSelector);
  const numberOfLoyaltyPrograms = config.loyaltyPrograms?.length;

  return (
    <div
      className={`md-p5 ${styles.outerBox} ${styles.offset}`}
      id="partnership-banner"
    >
      <div className={`py2 md-py6 ${styles.innerBox}`}>
        <ContentColumn
          className="sb-global-gutters--maxWidthLayout"
          innerClassName={styles.contentColumn}
          size="xl"
        >
          {numberOfLoyaltyPrograms > 2 ? (
            <div>
              <ContentHeading />
              <ProgramContent />
              <JoinButton />
            </div>
          ) : (
            <React.Fragment>
              <LeftStars />
              <div>
                <ContentHeading />
                <ProgramContent />
                <JoinButton />
              </div>
              <RightStars />
            </React.Fragment>
          )}
        </ContentColumn>
      </div>
    </div>
  );
};

export default LoyaltyPartnerships;
