import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'rewards.hero.header',
    description: 'Promotional heading text',
    defaultMessage: 'FREE COFFEE {newLine} IS A TAP AWAY',
  },
  body: {
    id: 'rewards.hero.body',
    description: 'Promotional text',
    defaultMessage: 'Join now to start earning Rewards.',
  },
  mobileCta: {
    id: 'rewards.hero.mobileCta2',
    description: 'Button text to indicate option to join Rewards on the web',
    defaultMessage: 'Or join online',
  },
  desktopCta: {
    id: 'rewards.hero.desktopCta2',
    description: 'Button text to indicate option to join Rewards on the app',
    defaultMessage: 'join in the app',
  },
  forTheBestExperience: {
    id: 'rewards.hero.forTheBestExperience',
    description:
      'Helper text for the button to join the rewards app for the best experience',
    defaultMessage: 'Or {joinLink} for the best experience',
  },
});

export default messages;
