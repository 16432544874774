import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import ExternalLink from 'shared/app/components/external-link';
import { configSelector } from 'shared/app/shell';

import ArrowLink from '../../shared/arrow-link';

import messages from './messages';
import styles from '../../shared/styles.cssm';

export const aeroplanTermsDescriptionId = 'aeroplanTermsDescriptionId';
export const tdBankTermsDescriptionId = 'tdBankTermsDescriptionId';

const TermsCa = () => {
  const aeroplanConditionsLink =
    useSelector(configSelector)?.aeroplanConditionsLink;
  const tdBankConditionsLink =
    useSelector(configSelector)?.tdBankConditionsLink;
  return (
    <React.Fragment>
      <p className={`pb2 ${styles.target}`} id={aeroplanTermsDescriptionId}>
        <FormattedMessage
          {...messages.aeroplanTermsDescription}
          values={{
            sup: <sup>1</sup>,
            link: (content) => (
              <ExternalLink href={aeroplanConditionsLink}>
                {content}
              </ExternalLink>
            ),
          }}
        />
        <ArrowLink href="#aeroplanNoteId" />
      </p>
      <p className={`pb2 ${styles.target}`} id={tdBankTermsDescriptionId}>
        <FormattedMessage
          {...messages.tdBankTermsDescription}
          values={{
            sup: <sup>2</sup>,
            link: (content) => (
              <ExternalLink href={tdBankConditionsLink}>{content}</ExternalLink>
            ),
          }}
        />
        <ArrowLink href="#tdBankNoteId" />
      </p>
      <p className="mb2">
        <FormattedMessage {...messages.aeroplanTrademarkDescription} />
      </p>
      <p className="mb2">
        <FormattedMessage {...messages.airCanadaTrademarkDescription} />
      </p>
      <p className="mb2">
        <FormattedMessage {...messages.tdBankTrademarkDescription} />
      </p>
    </React.Fragment>
  );
};

export default TermsCa;
