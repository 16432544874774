import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';
import { configSelector } from 'shared/app/shell';
import { imageUrl } from '../../../../utils';
import messages from './messages';
import styles from '../../../shared/styles.cssm';
import { tdBankTermsDescriptionId } from '../../terms/terms-ca';

const tdBank = () => {
  const inlineLink = useSelector(configSelector)?.tdBankInlineLink;
  return {
    imageAlt: 'TD Bank',
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/td-bank.png`,
    imageWidth: '190px',
    description: (
      <FormattedMessage
        {...messages.tdBankProgramDescription}
        values={{
          link: (content) => (
            <ExternalLink href={inlineLink}>{content}</ExternalLink>
          ),
          tdBankNote2: (
            <a
              aria-labelledby="disclaimerTitleId"
              className={`text-noUnderline ${styles.target}`}
              href={`#${tdBankTermsDescriptionId}`}
              id="tdBankNoteId"
            >
              <sup>2</sup>
            </a>
          ),
        }}
      />
    ),
  };
};

tdBank.displayName = 'tdBank';

export default tdBank;
