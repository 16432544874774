import React from 'react';
import { connect } from 'react-redux';

import { isSmMaxViewportSelector } from 'shared/app/bundles/viewport';
import OfferOptIn from './index';
import messages from './messages';

const OptInSuccess = ({ isMobileViewport }) => {
  return (
    <OfferOptIn
      body1Text={messages.optInSuccessBody1}
      body2Text={messages.optInSuccessBody2}
      cta1Text={isMobileViewport ? messages.orderAppCta : messages.orderWebCta}
      cta2Text={isMobileViewport ? messages.orderWebCta : null}
      headingText={messages.optInSuccessHeader}
      imgHeight={256}
      imgPath="/rewards/offers/opt-in-success.svg"
      imgWidth={256}
    />
  );
};

const select = (state) => ({
  isMobileViewport: isSmMaxViewportSelector(state),
});

export default connect(select)(OptInSuccess);
