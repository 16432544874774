import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FlexEmbed from '@starbucks-web/pattern-library/lib/components/flex-embed';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';

import styles from './styles.cssm';
import HeroContent from './content';

const MOBILE_ASPECT_WIDTH = 3;
const MOBILE_ASPECT_HEIGHT = 4;
const DESKTOP_ASPECT_WIDTH = 16;
const DESKTOP_ASPECT_HEIGHT = 9;

const Hero = ({
  mobile: {
    backgroundColor: mobileBgColor,
    mobileBgUrl,
    body: mobileBody,
    containerClassName: mobileContainerClassName,
    mobileLogoImage,
    mobileLogoImageWidth,
    mobileCta1,
    mobileCta1TagName,
    mobileCta2,
    textAlign: mobileTextAlign = 'center',
    title: mobileTitle,
    titleClassName: mobileTitleClassName,
    titleHeadingLevel: mobileTitleHeadingLevel,
    verticalAlign: mobileVerticalAlign = 'top',
  },
  desktop: {
    backgroundColor: desktopBgColor,
    desktopBgUrl,
    body: desktopBody,
    containerClassName: desktopContainerClassName,
    desktopCta1,
    desktopCta1TagName,
    desktopCta2,
    horizontalAlign = 'left',
    desktopLogoImage,
    desktopLogoImageWidth,
    textAlign: desktopTextAlign = 'left',
    title: desktopTitle,
    titleClassName: desktopTitleClassName,
    titleHeadingLevel: desktopTitleHeadingLevel,
    verticalAlign: desktopVerticalAlign = 'middle',
  },
}) => (
  <Fragment>
    {/* Mobile Content */}
    <div className="md-hidden">
      <div
        className={`${styles.bgImage} flex`}
        style={{
          backgroundColor: mobileBgColor,
          backgroundImage: `url(${mobileBgUrl})`,
        }}
      >
        <FlexEmbed
          className="flex size12of12"
          height={MOBILE_ASPECT_HEIGHT}
          permitOverflow
          width={MOBILE_ASPECT_WIDTH}
        >
          <HeroContent
            body={mobileBody}
            bodyClassName="text-sm"
            containerClassName={mobileContainerClassName}
            cta1={mobileCta1}
            cta1TagName={mobileCta1TagName}
            cta2={mobileCta2}
            horizontalAlign="center"
            logoImage={mobileLogoImage}
            logoImageWidth={mobileLogoImageWidth}
            textAlign={mobileTextAlign}
            title={mobileTitle}
            titleClassName={mobileTitleClassName}
            titleHeadingLevel={mobileTitleHeadingLevel}
            verticalAlign={mobileVerticalAlign}
          />
        </FlexEmbed>
      </div>
    </div>

    {/* Desktop Content */}
    <div className="mdMax-hidden">
      <div
        className={`${styles.bgImage} flex`}
        style={{
          backgroundColor: desktopBgColor,
          backgroundImage: `url(${desktopBgUrl})`,
        }}
      >
        <ContentColumn className="size12of12 px0" innerClassName="flex">
          <FlexEmbed
            className="flex size12of12"
            height={DESKTOP_ASPECT_HEIGHT}
            permitOverflow
            width={DESKTOP_ASPECT_WIDTH}
          >
            <HeroContent
              body={desktopBody}
              bodyClassName="text-sm"
              containerClassName={desktopContainerClassName}
              cta1={desktopCta1}
              cta1TagName={desktopCta1TagName}
              cta2={desktopCta2}
              horizontalAlign={horizontalAlign}
              logoImage={desktopLogoImage}
              logoImageWidth={desktopLogoImageWidth}
              textAlign={desktopTextAlign}
              title={desktopTitle}
              titleClassName={desktopTitleClassName}
              titleHeadingLevel={desktopTitleHeadingLevel}
              verticalAlign={desktopVerticalAlign}
            />
          </FlexEmbed>
        </ContentColumn>
      </div>
    </div>
  </Fragment>
);

const ctaPropType = PropTypes.shape({
  href: PropTypes.string,
  text: PropTypes.string,
});

const heroViewProps = PropTypes.shape({
  body: PropTypes.node,
  cta1: ctaPropType,
  cta2: ctaPropType,
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  horizontalAlign: PropTypes.oneOf(['left', 'center', 'right']),
  backgroundUrl: PropTypes.string,
  logoImage: PropTypes.string,
  logoImageWidth: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  titleHeadingLevel: PropTypes.oneOf(['h1', 'h2', 'h3']),
});

Hero.propTypes = {
  mobile: heroViewProps,
  desktop: heroViewProps,
};

export default Hero;
