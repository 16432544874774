import config from 'config';
import formatUrl from 'shared/universal/format-url';

const { host, path } = config.get('universal.staticUrls.images', {});
export const imageUrl = formatUrl(host, path);

export const waysToPaySectionId = 'ways-to-pay';
export const waysToPayHash = '#waystopay';

// Height of global nav + rewards banner
export const NAV_BAR_HEIGHT_DESKTOP = 149;

// Height of rewards banner
export const NAV_BAR_HEIGHT_MOBILE = 50;

export const scrollSectionIntoView = (element, offset) => {
  if (!element) {
    return;
  }

  element.scrollIntoView();
  const scrolledY = window.scrollY;

  if (scrolledY) {
    window.scroll({
      left: 0,
      top: window.scrollY - offset,
      behavior: 'smooth',
    });
  }
};
