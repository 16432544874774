import { defineMessages } from 'react-intl';

const messages = defineMessages({
  stepsThumbnailAlt: {
    id: 'rewards.shared.thumbnailAlt',
    description: 'Alt for images indicating they are steps',
    defaultMessage: 'Step { stepNumber }',
  },
  errorHeading: {
    id: 'rewards.shared.errorHeading',
    defaultMessage: 'Uh oh!',
    description: 'Heading of rewards error page',
  },
  disclaimerBackToContent: {
    id: 'rewards.shared.disclaimerBackToContent',
    description:
      'Cta used in ArrowLink in footer messages to send user back to content that brought them to the terms superscripts',
    defaultMessage: 'Back to content',
  },
});

export default messages;
