import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { isSmMaxViewportSelector } from 'shared/app/bundles/viewport';
import ContentBlocks from '../../shared/content-blocks';

import { getMobileContent, getDesktopContent } from './getting-started-content';

import messages from './messages';

const trackingDisplayName = 'Rewards LP - Getting Started';

const GettingStarted = () => {
  const isMobileViewport = useSelector(isSmMaxViewportSelector);
  const content = isMobileViewport
    ? getMobileContent(trackingDisplayName)
    : getDesktopContent(trackingDisplayName);

  return (
    <ContentBlocks
      MultiThumbnailsRowTagName="ol"
      ThumbnailContentBlocksTagName="li"
      content={content}
      header={{
        heading: <FormattedMessage {...messages.header} />,
        subHeading: <FormattedMessage {...messages.subHeading} />,
      }}
    />
  );
};

export default GettingStarted;
