import { imageUrl } from '../../../../utils';

import { defineMessages } from 'react-intl';

const orderAndPayAheadMessages = defineMessages({
  headingContent1: {
    id: 'rewards.benefits.slides.orderAndPayAhead.1.headingContent',
    description: 'order and pay ahead slide 1 heading',
    defaultMessage: 'Tap, go.',
  },
  bodyContent1: {
    id: 'rewards.benefits.slides.orderAndPayAhead.1.bodyContent',
    description: 'order and pay ahead slide 1 body content',
    defaultMessage:
      'Select your menu items in the app along with your store location. Then tell the barista your name when you swing by the pickup area to grab your order.',
  },
  headingContent2: {
    id: 'rewards.benefits.slides.orderAndPayAhead.2.headingContent',
    description: 'order and pay ahead slide 2 heading',
    defaultMessage: 'Just for you',
  },
  bodyContent2: {
    id: 'rewards.benefits.slides.orderAndPayAhead.2.bodyContent',
    description: 'order and pay ahead slide 2 body content',
    defaultMessage:
      'Our app lets you customize your order and track an estimated pickup time. Make sure to save your favorites so they’re all ready for your next order.',
  },
});

export const getOrderAndPayAheadSlideData = (formatMessage) => [
  {
    imageUrl: `${imageUrl}/rewards/benefits/2A.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(orderAndPayAheadMessages.headingContent1),
    bodyContent: formatMessage(orderAndPayAheadMessages.bodyContent1),
  },
  {
    imageUrl: `${imageUrl}/rewards/benefits/2B.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(orderAndPayAheadMessages.headingContent2),
    bodyContent: formatMessage(orderAndPayAheadMessages.bodyContent2),
  },
];
