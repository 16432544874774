import React from 'react';
import { connect } from 'react-redux';

import { customerServiceUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';
import { isSmMaxViewportSelector } from 'shared/app/bundles/viewport';
import OfferOptIn from './index';
import messages from './messages';
import sharedMessages from '../shared/messages';

const OfferOptInError = ({ customerServiceUrl, isMobileViewport }) => {
  return (
    <OfferOptIn
      body1LinkText={messages.errorBody1LinkText}
      body1LinkUrl={customerServiceUrl}
      body1Text={messages.errorBody1}
      cta1Text={isMobileViewport ? messages.orderAppCta : messages.orderWebCta}
      cta2Text={isMobileViewport ? messages.orderWebCta : null}
      headingText={sharedMessages.errorHeading}
      imgHeight={256}
      imgPath="/rewards/offers/opt-in-error.svg"
      imgWidth={256}
    />
  );
};

const select = (state) => ({
  customerServiceUrl: customerServiceUrlSelector(state),
  isMobileViewport: isSmMaxViewportSelector(state),
});

export default connect(select)(OfferOptInError);
