import React, { useCallback, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import ContentBlocks from '../shared/content-blocks';
import Hero from './hero';
import StarbucksRewardsTrademark from 'shared/app/components/starbucks-rewards-trademark';
import { rewardsMessages } from 'shared/app/messages/rewards';
import { hashValueSelector } from 'shared/app/state/selectors/routes';
import { getPerksContent, getGettingStartedContent } from './marriott-content';
import MarriottFooter from './footer/footer';

import styles from './styles.cssm';
import sharedStyles from '../shared/styles.cssm';
import messages from './messages';

const PartnershipOverview = () => {
  const { formatMessage } = useIntl();
  const [terms1ReturnHash, setTerms1ReturnHash] = useState(
    'termsHeroReference1'
  );
  const urlHash = useSelector(hashValueSelector);

  const perksRef = useCallback((domEl) => {
    if (domEl !== null && urlHash === 'termsPerksReference1') {
      domEl.focus();
      domEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const perksContent = getPerksContent();
  const gettingStartedContent = getGettingStartedContent();

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {formatMessage(rewardsMessages.rewardsPartnershipsMarriott)}
        </title>
      </Helmet>
      <div className="relative bg-neutralWarm">
        <Hero setTerms1ReturnHash={setTerms1ReturnHash} />
      </div>
      <div className={`${styles.contentBlocks} bg-white`}>
        <ContentBlocks
          content={perksContent}
          header={{
            heading: (
              <span className={sharedStyles.target} id="termsPerksReference1">
                {formatMessage(messages.marriottPerksHeader, {
                  termsPerksReference1: (
                    <a
                      aria-labelledby="disclaimerTitleId"
                      className="text-noUnderline text-md"
                      href="#terms1Id"
                      onClick={() =>
                        setTerms1ReturnHash('termsPerksReference1')
                      }
                    >
                      <sup>1</sup>
                    </a>
                  ),
                })}
              </span>
            ),
          }}
          headingRef={perksRef}
        />
      </div>
      <div className={`${styles.contentBlocks} bg-ceramic`}>
        <ContentBlocks
          MultiThumbnailsRowTagName="ol"
          ThumbnailContentBlocksTagName="li"
          content={gettingStartedContent}
          header={{
            heading: formatMessage(messages.marriottGettingStartedHeader),
            subHeading: (
              <span
                className={sharedStyles.target}
                id="termsGettingStartedReference1"
              >
                <FormattedMessage
                  {...messages.marriottGettingStartedBody}
                  values={{
                    starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
                    superscriptOne: (
                      <a
                        aria-labelledby="disclaimerTitleId"
                        className="text-noUnderline"
                        href="#terms1Id"
                        onClick={() =>
                          setTerms1ReturnHash('termsGettingStartedReference1')
                        }
                      >
                        <sup>1</sup>
                      </a>
                    ),
                  }}
                />
              </span>
            ),
          }}
        />
      </div>
      <div className="mb7">
        <MarriottFooter terms1ReturnHash={terms1ReturnHash} />
      </div>
    </React.Fragment>
  );
};

export default PartnershipOverview;
