import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imageUrl } from '../../../utils';
import messages from './messages';
import JoinInAppCTA from './join-in-app';
import LearnHowCTA from './learn-how-cta';
import { getThreeStepAltText } from '../../shared/utils';

const getBlock2 = () => ({
  imageUrl: `${imageUrl}/rewards/getting-started/getting-started-2@2x.jpg`,
  imageAlt: getThreeStepAltText(2),
  heading: <FormattedMessage {...messages.heading2} />,
  description: (
    <FormattedMessage
      {...messages.body2}
      values={{
        learnHow: <LearnHowCTA />,
      }}
    />
  ),
});

const getBlock3 = () => ({
  imageUrl: `${imageUrl}/rewards/getting-started/getting-started-3@2x.jpg`,
  imageAlt: getThreeStepAltText(3),
  heading: <FormattedMessage {...messages.heading3} />,
  description: <FormattedMessage {...messages.body3} />,
});

export const getMobileContent = () => [
  {
    imageUrl: `${imageUrl}/rewards/getting-started/getting-started-1@2x.jpg`,
    imageAlt: getThreeStepAltText(1),
    heading: <FormattedMessage {...messages.heading1} />,
    description: (
      <FormattedMessage
        {...messages.body1}
        values={{
          joinInApp: <JoinInAppCTA />,
          joinOnline: (
            <a className="color-greenStarbucks" href="/account/create">
              <FormattedMessage {...messages.joinOnlineCTA} />
            </a>
          ),
        }}
      />
    ),
  },
  getBlock2(),
  getBlock3(),
];

export const getDesktopContent = () => [
  {
    imageUrl: `${imageUrl}/rewards/getting-started/getting-started-1@2x.jpg`,
    imageAlt: getThreeStepAltText(1),
    heading: <FormattedMessage {...messages.desktopHeading1} />,
    description: (
      <FormattedMessage
        {...messages.desktopBody1}
        values={{
          joinInApp: <JoinInAppCTA lowercase />,
          joinNow: (
            <a className="color-greenStarbucks" href="/account/create">
              <FormattedMessage {...messages.joinNowCTA} />
            </a>
          ),
        }}
      />
    ),
  },
  getBlock2(),
  getBlock3(),
];
