import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

const messages = defineMessages({
  loyaltyPartnershipsHeading: {
    id: 'rewards.loyaltyPartnerships.heading',
    description: 'Loyalty Partnerships heading',
    defaultMessage: 'Keep the Rewards Coming',
  },
  loyaltyPartnershipsContent: {
    id: 'rewards.loyaltyPartnerships.content',
    description: 'Loyalty Partnerships content',
    defaultMessage:
      "The Rewards don't stop at your morning coffee. Join Starbucks® Rewards and unlock perks from our partners, all while earning more Stars.",
  },
});

const ContentHeadings = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="text-center size12of12">
      <Heading className="text-semibold pt3" size="lg" tagName="h2">
        {formatMessage(messages.loyaltyPartnershipsHeading)}
      </Heading>
      <p className="text-center pt3">
        {formatMessage(messages.loyaltyPartnershipsContent)}
      </p>
    </div>
  );
};

export default ContentHeadings;
