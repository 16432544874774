import { defineMessages } from 'react-intl';

const messages = defineMessages({
  joinInAppCTA: {
    id: 'rewards.shared.joinInAppCTA',
    description: 'join in app CTA on the Rewards banner',
    defaultMessage: 'Join in the app',
  },
  title: {
    id: 'rewards.shared.pageTitle',
    description: 'Helmet title for the Rewards page',
    defaultMessage:
      'Starbucks® Rewards – Order Ahead, Endless Extras, Free Coffee',
  },
  rewardsDescription: {
    id: 'rewards.shared.pageDescription',
    description: 'Helmet description of the Rewards page',
    defaultMessage:
      'Starbucks® Rewards is even easier to join and has more ways to earn free food and drink, whether you pay using the Starbucks app, cash or a card.',
  },
  questionsHeader: {
    id: 'rewards.questions.header',
    description: 'Questions section heading',
    defaultMessage: 'Questions?',
  },
  questionsDescription: {
    id: 'rewards.questions.description',
    description: 'Questions section description',
    defaultMessage:
      'We want to help in any way we can. You can ask your barista anytime or we’ve answered the most commonly asked terms <link>right over here</link>.',
  },
});

export default messages;
