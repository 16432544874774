import { createSelector } from 'reselect';

export const configSelector = (state) => state.config;

export const appDownloadLinkSelector = createSelector(
  configSelector,
  (config) => config.appDownloadLink
);

export const mobileAppsLandingPageUrlSelector = createSelector(
  configSelector,
  (config) => config.mobileAppsLandingPageUrl
);

export const commonQuestionsUrlSelector = createSelector(
  configSelector,
  (config) => config.commonQuestionsUrl
);

export const mobileMenuBranchLinkSelector = createSelector(
  configSelector,
  (config) => config.mobileMenuBranchLink
);
