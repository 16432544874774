import { defineMessages } from 'react-intl';

export default defineMessages({
  successHeader: {
    id: 'rewards.marriottResultPages.successHeader',
    defaultMessage: "You're all set!",
    description: 'Marriott success page header',
  },
  successBody: {
    id: 'rewards.marriottResultPages.successBody',
    defaultMessage:
      "Congratulations, your accounts are linked. You're now ready to start earning benefits!",
    description: 'Marriott success page body',
  },
  seeBenefitsCta: {
    id: 'rewards.marriottResultPages.seeBenefitsCta',
    defaultMessage: 'See benefits',
    description: 'Marriott see benefits cta',
  },
  errorHeader: {
    id: 'rewards.marriottResultPages.errorHeader',
    defaultMessage: 'Hmm.',
    description: 'Marriott error page header',
  },
  errorBody: {
    id: 'rewards.marriottResultPages.errorBody',
    defaultMessage:
      "Something didn't go as planned, but we're on it! Please try again.",
    description: 'Marriott  error page body',
  },
  errorCta: {
    id: 'rewards.marriottResultPages.errorCta',
    defaultMessage: 'Back to Partnership page',
    description: 'Marriott error page cta',
  },
  'unlinkSuccessHeader': {
    id: 'rewards.marriott.unlinkSuccessHeader',
    defaultMessage: 'Your accounts are now unlinked.',
    description: 'Title for Successful Unlink Result Page',
  },
  'unlinkSuccessBody': {
    id: 'rewards.marriott.unlinkSuccessBody',
    defaultMessage:
      'We’re sad to see you go.  If you change your mind, you can always <relinkAccounts>relink your accounts</relinkAccounts>.',
    description: 'Body content for Successful Unlink Result Page',
  },
  'orderWithStarbucksCta': {
    id: 'rewards.marriott.orderCta',
    defaultMessage: 'Order with Starbucks',
    description: 'Order Starbucks CTA',
  },
  neverLinkedHeader: {
    id: 'rewards.marriottResultPages.neverLinkedHeader',
    defaultMessage: 'You’re actually not linked!',
    description: 'Marriott neverLinked page header',
  },
  neverLinkedBody: {
    id: 'rewards.marriottResultPages.neverLinkedBody',
    defaultMessage: 'It looks like you haven’t linked your accounts yet.',
    description: 'Marriott neverLinked page body',
  },
  alreadyLinkedHeader: {
    id: 'rewards.marriottResultPages.alreadyLinkedHeader',
    defaultMessage: "You're already linked!",
    description: 'Marriott alreadyLinked page header',
  },
  alreadyLinkedBody: {
    id: 'rewards.marriottResultPages.alreadyLinkedBody',
    defaultMessage:
      'You are on your way toward earning more of what you love—Stars, points and exciting experiences.',
    description: 'Marriott alreadyLinked page body',
  },
});
