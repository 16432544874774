import { tabContent } from './messages';
import { imageUrl } from '../../../utils';

export default (locale) => {
  const isUSLocale = locale === 'en-US';

  return [
    {
      imageUrl: `${imageUrl}/rewards/reward-tiers/25.png`,
      tabContentHeaderMessage: tabContent.tab1Header,
      tabContentBodyMessage: tabContent.tab1Content,
      tabTitleMessage: tabContent.tab1Title,
    },
    {
      imageUrl: `${imageUrl}/rewards/reward-tiers/100.png`,
      tabContentHeaderMessage: tabContent.tab2Header,
      tabContentBodyMessage: tabContent.tab2Content,
      tabTitleMessage: tabContent.tab2Title,
    },
    {
      imageUrl: `${imageUrl}/rewards/reward-tiers/200.png`,
      tabContentHeaderMessage: tabContent.tab3Header,
      tabContentBodyMessage: tabContent.tab3Content,
      tabTitleMessage: tabContent.tab3Title,
    },
    {
      imageUrl: isUSLocale
        ? `${imageUrl}/rewards/reward-tiers/300.png`
        : `${imageUrl}/rewards/reward-tiers/300-ca.png`,
      tabContentHeaderMessage: tabContent.tab4Header,
      tabContentBodyMessage: tabContent.tab4Content,
      tabTitleMessage: tabContent.tab4Title,
    },
    {
      imageUrl: `${imageUrl}/rewards/reward-tiers/400.png`,
      tabContentHeaderMessage: tabContent.tab5Header,
      tabContentBodyMessage: tabContent.tab5Content,
      tabTitleMessage: tabContent.tab5Title,
    },
  ];
};
