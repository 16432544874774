import { createSelector } from 'reselect';
import { configSelector } from 'shared/app/shell';

import { queryParamsSelector } from 'shared/app/state/selectors/routes';

const promoCodesSelector = (state) => state.rewards;

export const branchScriptSelector = createSelector(
  configSelector,
  (config) => config?.universal?.branch?.initCode
);

export const branchAppKeySelector = createSelector(
  configSelector,
  (config) => config?.universal?.branch?.appKey
);

export const branchMatchIdSelector = createSelector(
  queryParamsSelector,
  (params) => params?._branch_match_id
);

export const promoStatusSelector = createSelector(
  promoCodesSelector,
  (promoCodes) => {
    if (!promoCodes) return null;
    if (promoCodes.loading) return 'loading';
    if (promoCodes.error) return 'error';
    if (promoCodes.permanentFail) return 'failed';
    if (promoCodes.data) return 'success';
    return null;
  }
);

export const promoErrorCodeSelector = createSelector(
  promoCodesSelector,
  (promo) => (!promo?.data && promo?.error?.code) || null
);

export const promoSuccessMessageSelector = createSelector(
  promoCodesSelector,
  (promo) => promo?.data?.redeemPromoCode?.message || null
);
