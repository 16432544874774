import React from 'react';
import { FormattedMessage } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';
import { imageUrl } from '../../../../utils';
import messages from './messages';
import styles from '../../../shared/styles.cssm';
import { bankOfAmericaTermsDescriptionId } from '../../terms/terms-us';

const bankOfAmerica = () => {
  const boaLink =
    'https://promotions.bankofamerica.com/starbucks?cm_mmc=ESZ-EnterpriseSales-_-Cobrand_Site-_-ES35LT0004_StarbucksRewardsModuleBofA_SRModuleBofA-_-StarbucksPartnerMktg';
  return {
    imageAlt: 'Bank of America',
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/bank-of-america.png`,
    imageWidth: '190px',
    description: (
      <FormattedMessage
        {...messages.bankOfAmericaProgramDescription}
        values={{
          link: (content) => (
            <ExternalLink href={boaLink}>{content}</ExternalLink>
          ),
          bankOfAmericaNote2: (
            <a
              aria-labelledby="disclaimerTitleId"
              className={`text-noUnderline ${styles.target}`}
              href={`#${bankOfAmericaTermsDescriptionId}`}
              id="bankOfAmericaNoteId"
            >
              <sup>2</sup>
            </a>
          ),
        }}
      />
    ),
  };
};

bankOfAmerica.displayName = 'bankOfAmerica';

export default bankOfAmerica;
