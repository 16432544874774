import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';

import StarbucksRewardsTrademark from 'shared/app/components/starbucks-rewards-trademark';
import { imageUrl } from '../../../utils';
import redirectToSigninWithReturnUrl from 'shared/app/utils/redirect-to-signin-with-return-url';
import { hasShortSessionSelector } from 'shared/app/bundles/user';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import { rewardsMessages } from 'shared/app/messages/rewards';

import MarriottBanner from '../../shared/marriott-banner';
import styles from '../../shared/styles.cssm';
import messages from '../messages';

const PartnershipHero = ({ setTerms1ReturnHash }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const signedIn = useSelector(hasShortSessionSelector);
  const isMobileViewport = useSelector(isMobileViewportSelector);

  const partnershipLogo = `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/partnership-logo.svg`;
  const redirectLink = '/rewards/partnerships/marriottbonvoy/redirect';

  const logoImage = (
    <ImageLoader
      alt={formatMessage(rewardsMessages.rewardsPartnershipsMarriott)}
      lazyLoad={{ enabled: false }}
      src={partnershipLogo}
      width={isMobileViewport ? '233px' : '277px'}
    />
  );

  const onClick = () => {
    if (!signedIn) {
      return redirectToSigninWithReturnUrl({ returnUrl: redirectLink });
    }
    dispatch(push(redirectLink));
  };

  const body = (
    <React.Fragment>
      <p className="text-bold text-xl pb3">
        {formatMessage(messages.marriottHeroHeader)}
      </p>
      <p className={`pb3 ${styles.target}`} id="termsHeroReference1">
        {
          <FormattedMessage
            {...messages.marriottHeroBody}
            values={{
              starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
              superscriptOne: (
                <a
                  aria-labelledby="disclaimerTitleId"
                  className="text-noUnderline"
                  href="#terms1Id"
                  onClick={() => setTerms1ReturnHash('termsHeroReference1')}
                >
                  <sup>1</sup>
                </a>
              ),
            }}
          />
        }
      </p>
    </React.Fragment>
  );

  return (
    <MarriottBanner
      BodyTagName="div"
      body={body}
      ctaOnClick={onClick}
      ctaTagName={Frap}
      ctaText={formatMessage(messages.marriottHeroCta)}
      heading={logoImage}
      imagePath="rewards/loyalty-partnerships/marriott-bonvoy/hero.png"
    />
  );
};

export default PartnershipHero;
