import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'rewards.waysToPay.heading',
    description: 'Ways to pay heading section',
    defaultMessage: 'Cash or card, you earn Stars',
  },
  description: {
    id: 'rewards.waysToPay.description',
    description: 'Ways to pay and how to collect stars info',
    defaultMessage:
      'No matter how you pay, you can earn Stars with your morning coffee. Those Stars add up to (really delicious) Rewards.',
  },
  upToXStars: {
    id: 'rewards.waysToPay.upToXStars',
    description: 'max number of stars earned per dollar',
    defaultMessage: 'Up to {starsCount}{starIcon} per dollar',
  },
  payAsYouGo: {
    id: 'rewards.waysToPay.payAsYouGo',
    description: 'sub heading for 1st way to pay',
    defaultMessage: 'Pay as you go',
  },
  perDollar: {
    id: 'rewards.waysToPay.perDollar',
    description:
      'Supports phrasing for number of stars earned per dollar in rewards purchases',
    defaultMessage: 'per dollar',
  },
  addFunds: {
    id: 'rewards.waysToPay.addFunds',
    description: 'sub heading for 2nd way to pay',
    defaultMessage: 'Add funds in the app',
  },
  waysToCollect1Heading1: {
    id: 'rewards.waysToPay.waysToCollect1Heading1',
    description: '1st way on how to collect star card 1 heading',
    defaultMessage: 'Scan and pay separately',
  },
  waysToCollect1Description1: {
    id: 'rewards.waysToPay.waysToCollect1Description1',
    description: '1st way on how to collect star card 1 description',
    defaultMessage: 'Use cash or credit/debit card at the register.',
  },

  waysToCollect1Heading2: {
    id: 'rewards.waysToPay.waysToCollect1Heading2',
    description: '1st way on how to collect star card 2 heading',
    defaultMessage: 'Save payment in the app',
  },
  waysToCollect1Description2: {
    id: 'rewards.waysToPay.waysToCollect1Description2',
    description: '1st way on how to collect star card 2 description',
    defaultMessage:
      'Check-out faster by saving a credit/debit card or PayPal to your account. You’ll be able to order ahead or scan and pay at the register in one step.',
  },
  waysToCollect2Heading1: {
    id: 'rewards.waysToPay.waysToCollect2Heading1',
    description: '2nd way on how to collect star card 1 heading',
    defaultMessage: 'Preload',
  },
  waysToCollect2Description1: {
    id: 'rewards.waysToPay.waysToCollect2Description1',
    description: '2nd way on how to collect star card 1 description',
    defaultMessage:
      'To save time and earn Stars twice as fast, add money to your digital Starbucks Card using any payment option. Scan and pay in one step or order ahead in the app.',
  },
  waysToCollect2Heading2: {
    id: 'rewards.waysToPay.waysToCollect2Heading2',
    description: '2nd way on how to collect star card 2 heading',
    defaultMessage: 'Register your gift card',
  },
  waysToCollect2Description2: {
    id: 'rewards.waysToPay.waysToCollect2Description2',
    description: '2nd way on how to collect star card 2 description',
    defaultMessage:
      'Then use it to pay through the app. You can even consolidate balances from multiple cards in one place.',
  },
  creditLabel: {
    id: 'rewards.waysToPay.creditLabel',
    description: 'label for the credit rewards visa card',
    defaultMessage: 'Credit',
  },
});
