import React from 'react';
import { FormattedMessage } from 'react-intl';

import StarIcon from '../../shared/star-icon';
import ThumbnailContentBlock from '../../shared/thumbnail-content-block';

import { commonMessages } from 'shared/app/messages';
import messages from './messages';

const WayToCollectRow = ({ cards = [], starCount, subHeader }) => (
  <div className="grid">
    {starCount && (
      <div className="gridItem size12of12 lg-size2of12 pb5 lg-pb0">
        <p className="text-semibold text-md">
          {starCount}
          <StarIcon
            textColorClassName="color-black"
            textSizeClassName="text-sm"
          />
          <span className="hiddenVisually">
            {starCount === 1 ? (
              <FormattedMessage {...commonMessages.star} />
            ) : (
              <FormattedMessage {...commonMessages.stars} />
            )}
          </span>
          &nbsp;
          <span>
            <FormattedMessage {...messages.perDollar} />
          </span>
        </p>
        {subHeader && <p>{subHeader}</p>}
      </div>
    )}
    {cards.map((card, idx) => (
      <div
        className="gridItem pb5 md-pb0 size12of12 md-size6of12 lg-size5of12 xl-size4of12"
        key={`way-to-collect-content-block-${idx}`}
      >
        <ThumbnailContentBlock
          containerClasses="flex md-pr2 lg-px4"
          ctas={card.ctas}
          description={card.description}
          headingProps={{ message: card.heading, tagName: 'h3' }}
          imageAlt={card.imageAlt}
          imageUrl={card.imageUrl}
          subHeading={card.subHeading}
        />
      </div>
    ))}
  </div>
);

export default WayToCollectRow;
