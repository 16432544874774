import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { rewardsTermsOfUseUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';

import RewardsFooter from '../../shared/rewards-footer';
import messages from './messages';
import getTermsCopy from './terms-copy';
import TermsUs from './terms-us';
import TermsCa from './terms-ca';

const Terms = () => {
  const { formatMessage } = useIntl();
  const rewardsTermsUrl = useSelector(rewardsTermsOfUseUrlSelector);
  const locale = useSelector(localeTagSelector);
  const isUSLocale = locale === 'en-US';
  const terms = getTermsCopy({ isUSLocale, rewardsTermsUrl });

  return (
    <RewardsFooter
      hiddenTitle
      localeTerms={isUSLocale ? <TermsUs /> : <TermsCa />}
      mainBody={formatMessage(messages.mainBody)}
      mainBodyPadding="pb2"
      terms={terms}
    />
  );
};

export default Terms;
