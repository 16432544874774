import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import Button from '@starbucks-web/pattern-library/lib/components/button';

import redirectToSigninWithReturnUrl from 'shared/app/utils/redirect-to-signin-with-return-url';
import { hasShortSessionSelector } from 'shared/app/bundles/user';
import { rewardsMessages } from 'shared/app/messages/rewards';

import MarriottResultPage from '.';
import messages from './messages';

const UnlinkSuccess = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const signedIn = useSelector(hasShortSessionSelector);

  const REDIRECT_LINK = '/rewards/partnerships/marriottbonvoy/redirect';

  const onClick = () => {
    if (!signedIn) {
      return redirectToSigninWithReturnUrl({ returnUrl: REDIRECT_LINK });
    }
    dispatch(push(REDIRECT_LINK));
  };
  const body = (
    <FormattedMessage
      {...messages.unlinkSuccessBody}
      values={{
        relinkAccounts: (content) => (
          <Button
            className="color-greenStarbucks"
            onClick={onClick}
            visualStyle="textLink"
          >
            {content}
          </Button>
        ),
      }}
    />
  );

  return (
    <MarriottResultPage
      body={body}
      ctaHref="/menu"
      ctaText={formatMessage(messages.orderWithStarbucksCta)}
      heading={formatMessage(messages.unlinkSuccessHeader)}
      imageAlt={formatMessage(rewardsMessages.rewardsPartnershipsMarriott)}
      imagePath="rewards/loyalty-partnerships/marriott-bonvoy/success.png"
    />
  );
};

export default UnlinkSuccess;
