import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { variables as patternLibraryVariables } from '@starbucks-web/pattern-library/lib/shared-variables';

import Hero from '../../shared/hero';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import {
  appDownloadLinkSelector,
  mobileAppsLandingPageUrlSelector,
} from '../../../state/selectors';

import messages from './messages';
import sharedMessages from '../messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

const RewardsHero = ({
  appDownloadLink,
  imageBaseUrl,
  intl: { formatMessage },
  mobileAppsLandingPageUrl,
}) => {
  const body = <p>{<FormattedMessage {...messages.body} />}</p>;

  const mobileProps = {
    body,
    backgroundColor: patternLibraryVariables.colorGreenLight,
    mobileBgUrl: `${imageBaseUrl}/rewards/hero/hero-mobile_2021.jpg`,
    mobileCta1: {
      href: appDownloadLink,
      children: formatMessage(sharedMessages.joinInAppCTA),
      visualStyle: 'positive',
    },
    mobileCta2: {
      href: '/account/create',
      children: formatMessage(messages.mobileCta),
    },
    title: (
      <FormattedMessage {...messages.header} values={{ newLine: <br /> }} />
    ),
    titleClassName: 'text-semibold',
    titleHeadingLevel: 'h2',
  };

  const desktopProps = {
    body,
    backgroundColor: patternLibraryVariables.colorGreenLight,
    desktopBgUrl: `${imageBaseUrl}/rewards/hero/xl-hero-desktop_2021.png`,
    desktopCta1: {
      href: '/account/create',
      children: formatMessage(sharedCallsToAction.joinNow),
      visualStyle: 'positive',
    },
    desktopCta2: {
      tagName: 'span',
      className: 'text-noUnderline',
      children: (
        <FormattedMessage
          {...messages.forTheBestExperience}
          values={{
            joinLink: (
              <a className="color-textBlack" href={mobileAppsLandingPageUrl}>
                {formatMessage(messages.desktopCta)}
              </a>
            ),
          }}
        />
      ),
    },
    textAlign: 'left',
    title: (
      <FormattedMessage {...messages.header} values={{ newLine: <br /> }} />
    ),
    titleClassName: 'text-semibold',
    titleHeadingLevel: 'h2',
  };

  return <Hero desktop={{ ...desktopProps }} mobile={{ ...mobileProps }} />;
};

const select = (state) => ({
  appDownloadLink: appDownloadLinkSelector(state),
  imageBaseUrl: configImagesUrlSelector(state),
  mobileAppsLandingPageUrl: mobileAppsLandingPageUrlSelector(state),
});

export default connect(select)(injectIntl(RewardsHero));
