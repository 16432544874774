import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageState from '@starbucks-web/pattern-library/lib/components/image-state';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import styles from './styles.cssm';

const ThumbnailContentBlock = ({
  containerClasses,
  contentClasses,
  imageUrl,
  imageAlt,
  imageProps,
  onImageClick,
  headingProps,
  subHeading,
  description,
  ctas,
  imagePosition = 'left',
  ThumbnailContentBlocksTagName = 'div',
}) => {
  return (
    <ThumbnailContentBlocksTagName
      className={classNames({
        [containerClasses]: Boolean(containerClasses),
        [styles.cardContainer]: !containerClasses,
      })}
    >
      <ImageState src={imageUrl}>
        {({ completed, source, width }) => {
          if (completed) {
            return (
              <div
                className={classNames(
                  `${styles.image} text-center flex-shrink-none`,
                  {
                    'pr3 m0': imagePosition === 'left',
                    pb4: imagePosition === 'top',
                    'cursor-pointer': Boolean(onImageClick),
                  }
                )}
                onClick={() => onImageClick && onImageClick()}
              >
                <ImageLoader
                  alt={imageAlt}
                  src={source}
                  width={`${width / 2}`}
                  {...imageProps}
                />
              </div>
            );
          }
          // return a fallback image here?
          return <div />;
        }}
      </ImageState>
      <div
        className={classNames({
          [contentClasses]: Boolean(contentClasses),
          [styles.cardContent]: !contentClasses,
          'text-left': imagePosition === 'left',
          'text-center': imagePosition === 'top',
        })}
      >
        {headingProps && (
          <Heading
            className="pb1 text-semibold"
            size="sm"
            tagName={headingProps.tagName || 'h2'}
          >
            {headingProps.message}
          </Heading>
        )}
        {subHeading && (
          <p className={`${styles.subHeading} color-textBlackSoft text-xxs`}>
            {subHeading}
          </p>
        )}
        {description && (
          <p className={subHeading ? 'pt1' : 'pt3'}>{description}</p>
        )}
        {ctas}
      </div>
    </ThumbnailContentBlocksTagName>
  );
};

ThumbnailContentBlock.propTypes = {
  containerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imagePosition: PropTypes.oneOf(['left', 'top']),
  onImageClick: PropTypes.func,
  heading: PropTypes.object,
  subHeading: PropTypes.node,
  description: PropTypes.node,
  ctas: PropTypes.node,
};

export default ThumbnailContentBlock;
