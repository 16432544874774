import React from 'react';
import { useIntl } from 'react-intl';
import ExternalLink from 'shared/app/components/external-link';

import messages from './messages';

const getTermsCopy = ({ isUSLocale, rewardsTermsUrl }) => {
  const { formatMessage } = useIntl();

  const headingsClass = 'pb3 text-bold text-upper color-textBlackSoft';

  return [
    {
      header: formatMessage(messages.question1Header),
      descriptions: [
        formatMessage(messages.question1Description1),
        formatMessage(messages.question1Description2),
        formatMessage(messages.question1Description3),
      ],
      headingClassName: headingsClass,
      headingSize: 'xxs',
      headingTagName: 'h3',
    },
    {
      header: formatMessage(messages.question3Header),
      descriptions: [formatMessage(messages.question3Description)],
      headingClassName: headingsClass,
      headingSize: 'xxs',
      headingTagName: 'h3',
    },
    {
      header: formatMessage(messages.question4Header),
      descriptions: isUSLocale
        ? [
            formatMessage(messages.question4Description1, {
              link: (content) => (
                <ExternalLink href={rewardsTermsUrl}>{content}</ExternalLink>
              ),
            }),
            formatMessage(messages.question4Description2, {
              link: (content) => (
                <ExternalLink href="/store-locator">{content}</ExternalLink>
              ),
            }),
          ]
        : [
            formatMessage(messages.question4Description1, {
              link: (content) => (
                <ExternalLink href={rewardsTermsUrl}>{content}</ExternalLink>
              ),
            }),
          ],
      headingClassName: headingsClass,
      headingSize: 'xxs',
      headingTagName: 'h3',
    },
    {
      header: formatMessage(messages.question2Header),
      descriptions: [formatMessage(messages.question2Description)],
      headingClassName: headingsClass,
      headingSize: 'xxs',
      headingTagName: 'h3',
    },
  ];
};

export default getTermsCopy;
