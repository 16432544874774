import React from 'react';
import { useIntl } from 'react-intl';
import { Slide } from '@starbucks-web/pattern-library/lib/components/swipe-deck';

import SwipeDeckWithMessages from 'shared/app/components/swipe-deck-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

const BenefitsSwipeDeck = ({ slideData }) => {
  const { formatMessage } = useIntl();
  return (
    <SwipeDeckWithMessages
      confirmButtonText={formatMessage(sharedCallsToAction.ok)}
    >
      {slideData.map((slideProps, idx) => (
        <Slide key={`swipe-deck-slide-${idx}`} {...slideProps} />
      ))}
    </SwipeDeckWithMessages>
  );
};

export default BenefitsSwipeDeck;
