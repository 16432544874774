import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import UnlinkAccountsDialog, {
  UNLINK_ACCOUNTS_DIALOG_ID,
} from './unlink-accounts-dialog';
import messages from './messages';

export const UnlinkAccounts = () => {
  const { openModal } = useModalContext();

  const openUnlinkDialog = () => {
    openModal({
      component: UnlinkAccountsDialog,
      ariaLabelledBy: UNLINK_ACCOUNTS_DIALOG_ID,
    });
  };

  return (
    <Button
      className="color-greenStarbucks"
      onClick={openUnlinkDialog}
      visualStyle="textLink"
    >
      <FormattedMessage {...messages.unlinkAccounts} />
    </Button>
  );
};

export default UnlinkAccounts;
