import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ExternalLink from '@starbucks-web/pattern-library/lib/components/external-link';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { marriottJoinLinkSelector } from 'shared/app/state/selectors/config';
import { hasShortSessionSelector } from 'shared/app/bundles/user';

import { imageUrl } from '../../utils';
import { getThreeStepAltText } from '../shared/utils';
import sharedStyles from '../shared/styles.cssm';
import {
  footerTermsDescriptionId2,
  footerTermsDescriptionId3,
  footerTermsDescriptionId4,
} from './footer/footer-content';

import messages from './messages';

export const getPerksContent = () => [
  {
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/perk1.png`,
    imageAlt: '',
    heading: <FormattedMessage {...messages.marriottPerksBlock1Header} />,
    description: (
      <span className={sharedStyles.target} id="termsPerks2Id">
        <FormattedMessage
          {...messages.marriottPerksBlock1Body}
          values={{
            termsPerksReference2: (
              <a
                aria-labelledby="disclaimerTitleId"
                className="text-noUnderline"
                href={`#${footerTermsDescriptionId2}`}
              >
                <sup>2</sup>
              </a>
            ),
          }}
        />
      </span>
    ),
  },
  {
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/perk2.png`,
    imageAlt: '',
    heading: <FormattedMessage {...messages.marriottPerksBlock2Header} />,
    description: (
      <span className={sharedStyles.target} id="termsPerks3Id">
        <FormattedMessage
          {...messages.marriottPerksBlock2Body}
          values={{
            termsPerksReference3: (
              <a
                aria-labelledby="disclaimerTitleId"
                className="text-noUnderline"
                href={`#${footerTermsDescriptionId3}`}
              >
                <sup>3</sup>
              </a>
            ),
          }}
        />
      </span>
    ),
  },
  {
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/perk3.png`,
    imageAlt: '',
    heading: <FormattedMessage {...messages.marriottPerksBlock3Header} />,
    description: (
      <span className={sharedStyles.target} id="termsPerks4Id">
        <FormattedMessage
          {...messages.marriottPerksBlock3Body}
          values={{
            cta: (content) => (
              <ExternalLink
                children={content}
                className="color-greenAccent"
                href="https://www.marriott.com/loyalty/redeem/partner/convertPoints.mi"
              />
            ),
            termsPerksReference4: (
              <a
                aria-labelledby="disclaimerTitleId"
                className="text-noUnderline"
                href={`#${footerTermsDescriptionId4}`}
              >
                <sup>4</sup>
              </a>
            ),
          }}
        />
      </span>
    ),
  },
];

export const getGettingStartedContent = () => {
  const marriottJoinLink = useSelector(marriottJoinLinkSelector);
  const signedIn = useSelector(hasShortSessionSelector);
  const encodedLinkingUrl = encodeURIComponent(
    '/rewards/partnerships/marriottbonvoy/redirect'
  );

  return [
    {
      imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/asset1.png`,
      imageAlt: getThreeStepAltText(1),
      heading: (
        <FormattedMessage {...messages.marriottGettingStartedBlock1Header} />
      ),
      description: (
        <FormattedMessage
          {...messages.marriottGettingStartedBlock1Body}
          values={{
            cta: (content) => (
              <ExternalLink
                children={content}
                className="color-greenAccent"
                href={marriottJoinLink}
              />
            ),
          }}
        />
      ),
    },
    {
      imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/asset2.png`,
      imageAlt: getThreeStepAltText(2),
      heading: (
        <FormattedMessage {...sharedCallsToAction.joinStarbucksRewards} />
      ),
      description: (
        <FormattedMessage
          {...messages.marriottGettingStartedBlock2Body}
          values={{
            ctaJoin: (content) => (
              <a
                className="color-greenAccent"
                href={`/account/create?returnUrl=${encodedLinkingUrl}`}
              >
                {content}
              </a>
            ),
            ctaSignIn: (content) => (
              <a
                className="color-greenAccent"
                href={`/account/signin?returnUrl=${encodedLinkingUrl}`}
              >
                {content}
              </a>
            ),
          }}
        />
      ),
    },
    {
      imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/asset3.png`,
      imageAlt: getThreeStepAltText(3),
      heading: (
        <FormattedMessage {...messages.marriottGettingStartedBlock3Header} />
      ),
      description: (
        <FormattedMessage
          {...messages.marriottGettingStartedBlock3Body}
          values={{
            ctaLink: (content) => (
              <a
                className="color-greenAccent"
                href={
                  signedIn
                    ? '/rewards/partnerships/marriottbonvoy/redirect'
                    : `/account/signin?returnUrl=${encodedLinkingUrl}`
                }
              >
                {content}
              </a>
            ),
            partnershipFAQs: (content) => (
              <ExternalLink
                children={content}
                className="color-greenAccent"
                href="https://customerservice.starbucks.com/sbux?id=topics&article=KB0013260"
              />
            ),
          }}
        />
      ),
    },
  ];
};
