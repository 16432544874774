import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import H1 from 'shared/app/components/h1';
import { commonMessages } from 'shared/app/messages';
import messages from './messages';
import styles from './styles.cssm';

const RewardsBanner = ({ appDownloadLink }) => (
  <div className={`${styles.banner} bg-houseGreen`}>
    <div className="sb-global-gutters sb-global-gutters--logoOffset sb-global-gutters--maxWidthLayout flex items-center justify-between height-100 xxl-pl0">
      <H1 className="color-textWhite text-upper text-bold" size="xxs">
        <FormattedMessage {...commonMessages.starbucksRewardsTrademark} />
      </H1>
      <Button
        className="md-hidden whiteSpace-noWrap flex-shrink-none"
        color="white"
        href={appDownloadLink}
        rel="noopener noreferrer"
      >
        <FormattedMessage {...messages.joinInAppCTA} />
      </Button>
    </div>
  </div>
);

const select = (state) => ({
  appDownloadLink: state.config.appDownloadLink,
});

export default connect(select)(RewardsBanner);
