import createActionTypes from 'shared/app/state/create-action-types';

export const {
  REDEEM_PROMO,
  REDEEM_PROMO_ERROR,
  REDEEM_PROMO_SUCCESS,
  FETCH_PARTNERSHIP_AUTH,
  FETCH_PARTNERSHIP_AUTH_SUCCESS,
  FETCH_PARTNERSHIP_AUTH_ERROR,
  UNLINK_PARTNERSHIP,
  UNLINK_PARTNERSHIP_SUCCESS,
  UNLINK_PARTNERSHIP_ERROR,
} = createActionTypes([
  'REDEEM_PROMO',
  'FETCH_PARTNERSHIP_AUTH',
  'UNLINK_PARTNERSHIP',
]);
