import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'rewards.gettingStarted.header',
    description: 'Promotional heading text',
    defaultMessage: 'Getting started is easy',
  },
  subHeading: {
    id: 'rewards.gettingStarted.subHeading',
    description: 'sub heading for the Getting started section',
    defaultMessage: 'Earn Stars and get rewarded in a few easy steps. ',
  },
  heading1: {
    id: 'rewards.gettingStarted.subHeading1',
    description: 'heading for the first content block',
    defaultMessage: 'Download the Starbucks® app',
  },
  heading2: {
    id: 'rewards.gettingStarted.subHeading2',
    description: 'heading for the second content block',
    defaultMessage: 'Order and pay how you’d like',
  },
  heading3: {
    id: 'rewards.gettingStarted.subHeading3',
    description: 'heading for the third content block',
    defaultMessage: 'Earn Stars, get Rewards',
  },
  body1: {
    id: 'rewards.gettingStarted.body1',
    description: 'body for the first content block',
    defaultMessage:
      '{ joinInApp } to get access to the full range of Starbucks® Rewards benefits. You can also { joinOnline }.',
  },
  body2: {
    id: 'rewards.gettingStarted.body2',
    description: 'body for the second content block',
    defaultMessage:
      'Use cash, credit/debit card or save some time and pay right through the app. You’ll collect Stars all ways. { learnHow }',
  },
  body3: {
    id: 'rewards.gettingStarted.body3',
    description: 'body for the third content block',
    defaultMessage:
      'As you earn Stars, you can redeem them for Rewards—like free food, drinks, and more. Start redeeming with as little as 25 Stars!',
  },
  desktopHeading1: {
    id: 'rewards.gettingStarted.desktopSubHeading1',
    description: 'heading for the first desktop content block',
    defaultMessage: 'Create an account',
  },
  desktopBody1: {
    id: 'rewards.gettingStarted.desktopBody1',
    description: 'body for the first desktop content block',
    defaultMessage:
      'To get started, { joinNow }. You can also { joinInApp } to get access to the full range of Starbucks® Rewards benefits.',
  },
  joinOnlineCTA: {
    id: 'rewards.gettingStarted.joinOnlineCTA',
    description: 'join online CTA for tablet and desktop',
    defaultMessage: 'join online',
  },
  joinNowCTA: {
    id: 'rewards.gettingStarted.joinNowCTA',
    description: 'join now CTA for tablet and desktop',
    defaultMessage: 'join now',
  },
  learnHowCTA: {
    id: 'rewards.gettingStarted.learnHow',
    description: 'Anchor link to ways to pay section',
    defaultMessage: 'Learn how',
  },
});

export default messages;
