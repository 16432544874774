import React from 'react';
import { FormattedMessage } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';
import { imageUrl } from '../../../../utils';
import messages from './messages';
import styles from '../../../shared/styles.cssm';
import { deltaSkymilesTermsDescriptionId } from '../../terms/terms-us';

const deltaSkymiles = () => {
  const deltaLink =
    'https://www.deltastarbucks.com/content/starbucks/en/overview.html?mkcpgn=dipd-sbux7042';
  return {
    imageAlt: 'Delta Skymiles',
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/delta-skymiles.png`,
    imageWidth: '190px',
    description: (
      <FormattedMessage
        {...messages.deltaSkymilesProgramDescription}
        values={{
          link: (content) => (
            <ExternalLink href={deltaLink}>{content}</ExternalLink>
          ),
          deltaSkymilesNote1: (
            <a
              aria-labelledby="disclaimerTitleId"
              className={`text-noUnderline ${styles.target}`}
              href={`#${deltaSkymilesTermsDescriptionId}`}
              id="deltaSkymilesNoteId"
            >
              <sup>1</sup>
            </a>
          ),
        }}
      />
    ),
  };
};

deltaSkymiles.displayName = 'deltaSkymiles';

export default deltaSkymiles;
