import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import { configSelector } from 'shared/app/shell';
import { getContent } from './get-content';

import styles from './styles.cssm';

const ProgramContent = () => {
  const { formatMessage } = useIntl();

  const loyaltyPrograms = useSelector(configSelector)?.loyaltyPrograms;

  const content = getContent(loyaltyPrograms, formatMessage);
  return (
    <ul className={styles.programContainer}>
      {content.map((program, idx) => {
        const { description, imageAlt, imageUrl, imageWidth } = program;
        const containerClasses = classNames('size12of12 md-pb0 text-center', {
          'md-size6of12': content.length > 1,
          'md-size4of12': content.length > 2,
          'md-mr4': idx === 0 && content.length > 1,
          'md-mx4': idx === 1 && content.length > 2,
          'md-ml4': idx === 2,
        });
        return (
          <li
            className={`${containerClasses} ${styles.contentContainer}`}
            key={`program-${idx}`}
          >
            <div className={`text-center ${styles.imageContainer}`}>
              <ImageLoader alt={imageAlt} src={imageUrl} width={imageWidth} />
            </div>

            {description && (
              <p className="pl3 text-left md-pt4 md-pl0 md-text-center">
                {description}
              </p>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ProgramContent;
