import React from 'react';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import styles from './styles.cssm';

const TabContent = ({ body, header, imageAlt, imageUrl }) => (
  <div
    className={`md-py5 md-flex justify-center items-center ${styles.tabContent}`}
  >
    <div className={`md-mr7 py5 md-py0 flex-grow ${styles.tabContentSection}`}>
      <ImageLoader
        alt={imageAlt}
        className={styles.tabContentImage}
        src={imageUrl}
        wrapInFlex={{
          enabled: true,
          width: 16,
          height: 9,
        }}
      />
    </div>
    <div className={`px3 flex-grow ${styles.tabContentSection}`}>
      <Heading
        className={`text-md text-semibold pb3 ${styles.tabContentHeader}`}
        tagName="h3"
      >
        {header}
      </Heading>
      <p className="text-center md-text-left">{body}</p>
    </div>
  </div>
);

export default TabContent;
