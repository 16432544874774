import React from 'react';
import { useIntl } from 'react-intl';
import MarriottResultPage from '.';
import messages from './messages';

const MarriottError = () => {
  const { formatMessage } = useIntl();
  return (
    <MarriottResultPage
      BodyTagName="h1"
      HeadingTagName="p"
      body={formatMessage(messages.errorBody)}
      ctaHref="/rewards/partnerships/marriottbonvoy"
      ctaText={formatMessage(messages.errorCta)}
      heading={formatMessage(messages.errorHeader)}
      imagePath="rewards/loyalty-partnerships/marriott-bonvoy/error.png"
    />
  );
};

export default MarriottError;
