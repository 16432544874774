import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

const JoinButton = () => (
  <div className="text-center">
    <Button className="mb2" href="/account/create" visualStyle="positive">
      <FormattedMessage {...sharedCallsToAction.joinStarbucksRewards} />
    </Button>
  </div>
);

export default JoinButton;
