import React from 'react';
import classNames from 'classnames';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

const FooterColumnItem = ({ term, i, isNotLastColumnItem }) => {
  const {
    header = '',
    descriptions = [],
    headingClassName = '',
    headingTagName = '',
    headingSize = '',
    headerId,
  } = term;
  return (
    <div className={isNotLastColumnItem && 'mb4'} key={`terms-list-item-${i}`}>
      <Heading
        className={headingClassName}
        id={headerId}
        size={headingSize}
        tagName={headingTagName}
      >
        {header}
      </Heading>
      {descriptions?.length
        ? descriptions.map((description, qIdx) => (
            <p
              className={classNames('text-xxs', {
                pb3: qIdx !== descriptions.length - 1,
              })}
              key={`terms-${i}-description-${qIdx}`}
            >
              {description}
            </p>
          ))
        : null}
    </div>
  );
};

export default FooterColumnItem;
