import React from 'react';

const StarIcon = ({
  textColorClassName = 'color-gold',
  textSizeClassName = 'text-sm',
}) => (
  <span aria-hidden className={`${textColorClassName} ${textSizeClassName}`}>
    ★
  </span>
);

export default StarIcon;
