import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import Overlay from '@starbucks-web/pattern-library/lib/components/overlay';
import ButtonKit from '@starbucks-web/pattern-library/lib/components/button-kit';

import { hasShortSessionSelector } from 'shared/app/bundles/user';
import redirectToSigninWithReturnUrl from 'shared/app/utils/redirect-to-signin-with-return-url';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import { partnershipUnlink } from '../../../state/action-creators';
import messages from './messages';

export const UNLINK_ACCOUNTS_DIALOG_ID = 'UnlinkAccountsDialogId';

const UnlinkAccountsDialog = () => {
  const dispatch = useDispatch();
  const { closeModal } = useModalContext();
  const signedIn = useSelector(hasShortSessionSelector);
  const unlinkRedirect = '/rewards/partnerships/marriottbonvoy/unlink-callback';

  const [showLoading, setShowLoading] = React.useState(false);

  const handleContinueClick = () => {
    if (!signedIn) {
      return redirectToSigninWithReturnUrl({
        returnUrl: unlinkRedirect,
      });
    }
    setShowLoading(true);
    dispatch(partnershipUnlink(closeModal));
  };

  return (
    <Overlay
      alignment="dialog"
      className="borderRadius-md"
      renderCloseButton={false}
    >
      <Heading id={UNLINK_ACCOUNTS_DIALOG_ID} tagName="h2">
        <p className="text-semibold px5 pt5 pb3">
          <FormattedMessage {...messages.marriottUnlinkDialogHeader} />
        </p>
      </Heading>
      <p className="px5">
        <FormattedMessage {...messages.marriottUnlinkDialogBody} />
      </p>
      <ButtonKit
        cancelProps={{ 'data-e2e': 'cancelButton' }}
        cancelText={<FormattedMessage {...sharedCallsToAction.cancel} />}
        className="p5"
        confirmProps={{
          'data-e2e': 'dialogConfirmButton',
          loading: showLoading,
        }}
        confirmText={
          <FormattedMessage {...messages.marriottUnlinkDialogConfirmButton} />
        }
        onCancel={() => closeModal()}
        onConfirm={handleContinueClick}
      />
    </Overlay>
  );
};

export default UnlinkAccountsDialog;
