import React from 'react';
import { useIntl } from 'react-intl';
import MarriottResultPage from '.';

import messages from './messages';

const NeverLinked = () => {
  const { formatMessage } = useIntl();
  return (
    <MarriottResultPage
      body={formatMessage(messages.neverLinkedBody)}
      ctaHref="/menu"
      ctaText={formatMessage(messages.orderWithStarbucksCta)}
      heading={formatMessage(messages.neverLinkedHeader)}
      imagePath="rewards/loyalty-partnerships/marriott-bonvoy/error.png"
    />
  );
};

export default NeverLinked;
