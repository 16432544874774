import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import Rule from '@starbucks-web/pattern-library/lib/components/rule';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import H1 from 'shared/app/components/h1';
import StarbucksRewardsTrademark from 'shared/app/components/starbucks-rewards-trademark';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { isSmMaxViewportSelector } from 'shared/app/bundles/viewport';

import { mobileMenuBranchLinkSelector } from '../../state/selectors';

import messages from './messages';

const OfferOptIn = ({
  isMobileViewport,
  imageBaseUrl,
  mobileMenuBranchLink,
  imgPath,
  headingText,
  body1LinkText,
  body1LinkUrl,
  body1Text,
  body2Text,
  cta1Text,
  cta2Text,
  imgWidth,
  imgHeight,
}) => {
  let firstCta = null;
  if (cta1Text) {
    firstCta = isMobileViewport ? (
      <Button
        className="mt3 mb3"
        href={mobileMenuBranchLink}
        target="_blank"
        visualStyle="positive"
      >
        <FormattedMessage {...cta1Text} />
      </Button>
    ) : (
      <Button className="mt3 mb3" href="/menu" visualStyle="positive">
        <FormattedMessage {...cta1Text} />
      </Button>
    );
  }

  const secondCta = !cta2Text ? null : (
    <React.Fragment>
      <br />
      <Button className="mb3" href="/menu">
        <FormattedMessage {...cta2Text} />
      </Button>
    </React.Fragment>
  );

  return (
    <ContentColumn className="py8" size="md">
      <ImageLoader
        includeFallback={{ enabled: true, fallbackClassName: 'bg-ceramic' }}
        lazyLoad={{ enabled: false }}
        role="presentation"
        src={`${imageBaseUrl}${imgPath}`}
        width={`${imgWidth}`}
        wrapInFlex={{
          enabled: true,
          width: imgWidth,
          height: imgHeight,
          constrainWidth: true,
        }}
      />
      <H1 className="pt8 color-textBlackSoft text-bold text-upper" size="xs">
        <StarbucksRewardsTrademark />
      </H1>
      <Heading className="text-bold pt4 pb3" size="xl" tagName="h2">
        <FormattedMessage {...headingText} />
      </Heading>
      <p className="pb3 text-sm">
        <FormattedMessage
          {...body1Text}
          values={{
            body1Link: (
              <Button
                className="color-greenStarbucks"
                href={body1LinkUrl}
                visualStyle="textLink"
              >
                <FormattedMessage {...body1LinkText} />
              </Button>
            ),
          }}
        />
      </p>
      {body2Text ? (
        <p className="pb3 text-sm">
          <FormattedMessage {...body2Text} />
        </p>
      ) : null}

      {firstCta}
      {secondCta}

      <Rule className="mt3 mb5" />
      <p className="color-textBlackSoft text-xxs">
        <FormattedMessage {...messages.disclaimer} />
      </p>
    </ContentColumn>
  );
};

OfferOptIn.propTypes = {
  imgPath: PropTypes.string.isRequired,
  headingText: PropTypes.object.isRequired,
  body1LinkText: PropTypes.object,
  body1Text: PropTypes.object.isRequired,
  body2Text: PropTypes.object,
  cta1Text: PropTypes.object,
  cta2Text: PropTypes.object,
  imgWidth: PropTypes.number.isRequired,
  imgHeight: PropTypes.number.isRequired,
};

const select = (state) => ({
  isMobileViewport: isSmMaxViewportSelector(state),
  imageBaseUrl: configImagesUrlSelector(state),
  mobileMenuBranchLink: mobileMenuBranchLinkSelector(state),
});

export default connect(select)(OfferOptIn);
