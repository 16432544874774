import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';

const JoinInAppCTA = ({ lowercase = false, appDownloadLink }) => (
  <a
    className="color-greenStarbucks"
    href={appDownloadLink}
    style={lowercase ? { textTransform: 'lowercase' } : null}
  >
    <FormattedMessage {...messages.joinInAppCTA} />
  </a>
);

const select = (state) => ({
  appDownloadLink: state.config.appDownloadLink,
});

export default connect(select)(JoinInAppCTA);
