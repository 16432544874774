import React from 'react';
import { useIntl } from 'react-intl';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { rewardsMessages } from 'shared/app/messages/rewards';
import FooterColumnItem from './footer-column-item';
import { splitFooterIntoColumns } from './utils';

const RewardsFooter = ({
  mainBody,
  localeTerms,
  terms,
  topHeading,
  bottomHeading,
  bottomBody,
  sidePadding,
  topPadding,
  bottomPadding,
  mainBodyPadding,
  hiddenTitle,
  termsIndexToSplit,
}) => {
  const { formatMessage } = useIntl();

  /**
   * Because design wants two columns of variable length footers,
   * termsIndexToSplit prop indicates where to split terms array
   */
  const { leftColumn, rightColumn } = splitFooterIntoColumns({
    terms,
    indexToSplit: termsIndexToSplit,
  });
  return (
    <div className="bg-neutralCool">
      <ContentColumn className="sb-global-gutters--maxWidthLayout" size="xl">
        {hiddenTitle ? (
          <h2 className="hiddenVisually" id="disclaimerTitleId">
            {formatMessage(rewardsMessages.termsAndConditions)}
          </h2>
        ) : null}
        <div className={`py5 md-py7 ${sidePadding ?? ''}`}>
          <div className="text-xxs mb5">
            {topHeading ? (
              <Heading
                className={`text-semibold ${topPadding}`}
                size="sm"
                tagName="h2"
              >
                {topHeading}
              </Heading>
            ) : null}
            <p className={mainBodyPadding}>{mainBody}</p>
            {localeTerms}
          </div>
          <div className="md-flex">
            <div className="mb4 md-mb0 md-size6of12">
              {leftColumn.map((term, i) => (
                <FooterColumnItem
                  i={i}
                  isNotLastColumnItem={leftColumn.length - 1 !== i}
                  term={term}
                />
              ))}
            </div>
            <div className="md-ml4 lg-ml8 md-size6of12">
              {rightColumn.map((term, i) => (
                <FooterColumnItem
                  i={i}
                  isNotLastColumnItem={rightColumn.length - 1 !== i}
                  term={term}
                />
              ))}
            </div>
          </div>

          {bottomHeading ? (
            <div className={`text-xxs mt5 ${bottomPadding}`}>
              <Heading className="text-semibold mb3" size="sm" tagName="h2">
                {bottomHeading}
              </Heading>
              <p>{bottomBody}</p>
            </div>
          ) : null}
        </div>
      </ContentColumn>
    </div>
  );
};

export default RewardsFooter;
