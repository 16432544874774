import React from 'react';
import { useIntl } from 'react-intl';
import ExternalLink from '@starbucks-web/pattern-library/lib/components/external-link';
import StarbucksRewardsTrademark from 'shared/app/components/starbucks-rewards-trademark';
import { rewardsMessages } from 'shared/app/messages/rewards';

import ArrowLink from '../../shared/arrow-link';

import messages from './messages';
import styles from '../../shared/styles.cssm';

export const footerTermsDescriptionId2 = 'footerTermsDescriptionId2';
export const footerTermsDescriptionId3 = 'footerTermsDescriptionId3';
export const footerTermsDescriptionId4 = 'footerTermsDescriptionId4';

const getFooterContent = ({ terms1ReturnHash }) => {
  const { formatMessage } = useIntl();

  const headingsClass = `color-textBlackSoft pb3 text-semibold ${styles.target}`;

  return [
    {
      header: formatMessage(rewardsMessages.termsAndConditions),
      headerId: 'disclaimerTitleId',
      descriptions: [
        <span className={styles.target} id="terms1Id">
          {formatMessage(messages.body1, {
            starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
            arrowLink: <ArrowLink href={`#${terms1ReturnHash}`} />,
            superscriptOne: <sup>1</sup>,
          })}
        </span>,
      ],
      headingClassName: 'text-semibold pb3',
      headingSize: 'sm',
      headingTagName: 'h2',
    },
    {
      header: formatMessage(messages.header2, {
        termsFooterReference2: <sup>2</sup>,
      }),
      descriptions: [
        formatMessage(messages.body2, {
          arrowLink: <ArrowLink href="#termsPerks2Id" />,
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }),
      ],
      headingClassName: headingsClass,
      headingSize: 'xs',
      headingTagName: 'h3',
      headerId: footerTermsDescriptionId2,
    },
    {
      header: formatMessage(messages.header3, {
        termsFooterReference3: <sup>3</sup>,
      }),
      descriptions: [
        formatMessage(messages.body3p1, {
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }),
        formatMessage(messages.body3p2, {
          arrowLink: <ArrowLink href="#termsPerks3Id" />,
        }),
      ],
      headingClassName: headingsClass,
      headingSize: 'xs',
      headingTagName: 'h3',
      headerId: footerTermsDescriptionId3,
    },
    {
      header: formatMessage(messages.header4, {
        termsFooterReference4: <sup>4</sup>,
      }),
      descriptions: [
        formatMessage(messages.body4p1, {
          cta: (content) => (
            <ExternalLink href="https://www.starbucks.com/rewards/terms/">
              {content}
            </ExternalLink>
          ),
          arrowLink: <ArrowLink href="#termsPerks4Id" />,
        }),
      ],
      headingClassName: headingsClass,
      headingSize: 'xs',
      headingTagName: 'h3',
      headerId: footerTermsDescriptionId4,
    },
    {
      header: formatMessage(messages.headerAddTerms),
      descriptions: [
        formatMessage(messages.bodyAddTermsP1, {
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }),
        formatMessage(messages.bodyAddTermsP2, {
          cta1: (content) => (
            <ExternalLink href="https://www.starbucks.com/rewards/terms/">
              {content}
            </ExternalLink>
          ),
          cta2: (content) => (
            <ExternalLink href="/rewards">{content}</ExternalLink>
          ),
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }),
        formatMessage(messages.bodyAddTermsP3, {
          cta1: (content) => (
            <ExternalLink href="https://www.marriott.com/loyalty/terms/default.mi?uclick_id=9ca434d2-cda8-4f61-a80f-cf5442c9a313">
              {content}
            </ExternalLink>
          ),
          cta2: (content) => (
            <ExternalLink href="https://www.marriott.com/about/privacy.mi">
              {content}
            </ExternalLink>
          ),
        }),
        formatMessage(messages.bodyAddTermsP4, {
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }),
        formatMessage(messages.bodyAddTermsP5, {
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }),
        formatMessage(messages.bodyAddTermsP6),
        formatMessage(messages.bodyAddTermsP7),
      ],
      headingClassName: 'color-textBlackSoft pb3 text-semibold',
      headingSize: 'xs',
      headingTagName: 'h3',
    },
  ];
};

export default getFooterContent;
