import React from 'react';
import { FormattedMessage } from 'react-intl';
import ExternalLink from 'shared/app/components/external-link';
import { rewardsMessages } from 'shared/app/messages/rewards';

import ArrowLink from '../../shared/arrow-link';

import messages from './messages';
import styles from '../../shared/styles.cssm';

export const deltaSkymilesTermsDescriptionId =
  'deltaSkymilesTermsDescriptionId';
export const bankOfAmericaTermsDescriptionId = 'bankOfAmericaTermDescriptionId';
export const marriottTermsDescriptionId = 'marriottTermsDescriptionId';

const TermsUs = () => {
  return (
    <React.Fragment>
      <p
        className={`pb2 ${styles.target}`}
        id={deltaSkymilesTermsDescriptionId}
      >
        <FormattedMessage
          {...messages.deltaTerms}
          values={{
            sup: <sup>1</sup>,
            link: (content) => (
              <ExternalLink href="https://www.deltastarbucks.com/terms">
                {content}
              </ExternalLink>
            ),
          }}
        />
        <ArrowLink href="#deltaSkymilesNoteId" />
      </p>
      <p
        className={`pb2 ${styles.target}`}
        id={bankOfAmericaTermsDescriptionId}
      >
        <FormattedMessage
          {...messages.bankOfAmericaTerms}
          values={{
            sup: <sup>2</sup>,
            link: (
              <ExternalLink href="https://promotions.bankofamerica.com/starbucks#disclosure-1223753557">
                <FormattedMessage {...rewardsMessages.termsAndConditions} />
              </ExternalLink>
            ),
          }}
        />
        <ArrowLink href="#bankOfAmericaNoteId" />
      </p>
      <p className={styles.target} id={marriottTermsDescriptionId}>
        <FormattedMessage
          {...messages.marriottTerms}
          values={{
            sup: <sup>3</sup>,
            link: (content) => (
              <ExternalLink
                href={'/rewards/partnerships/marriottbonvoy#disclaimerTitleId'}
              >
                {content}
              </ExternalLink>
            ),
          }}
        />
        <ArrowLink href="#marriottNoteId" />
      </p>
    </React.Fragment>
  );
};

export default TermsUs;
