import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Rule from '@starbucks-web/pattern-library/lib/components/rule';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import { hashSelector } from 'shared/app/state/selectors/routes';

import {
  waysToPayHash,
  waysToPaySectionId,
  scrollSectionIntoView,
  NAV_BAR_HEIGHT_DESKTOP,
  NAV_BAR_HEIGHT_MOBILE,
} from '../../../utils';

import WayToCollectRow from './way-to-collect-row';
import messages from './messages';
import { first, second } from './ways-to-collect-content';

const WaysToPayAndCollectStars = ({ isWaysToPayHash, isMobileViewport }) => {
  useEffect(() => {
    if (isWaysToPayHash) {
      const offset = isMobileViewport
        ? NAV_BAR_HEIGHT_MOBILE
        : NAV_BAR_HEIGHT_DESKTOP;

      setTimeout(() => {
        const elt = document.getElementById(waysToPaySectionId);
        scrollSectionIntoView(elt, offset);
      }, 0);
    }
  }, []);

  const contentData = [first, second];

  return (
    <div className="bg-neutralWarm" id={waysToPaySectionId}>
      <ContentColumn
        className="sb-global-gutters--maxWidthLayout py7"
        size="xl"
      >
        <div className="mb3 lg-mb4 pb7 md-pb9 md-offset2of12 md-size8of12 lg-offset3of12 lg-size6of12 text-center">
          <Heading
            className="pt4 md-pt0 pb3 text-semibold"
            size="lg"
            tagName="h2"
          >
            <FormattedMessage {...messages.header} />
          </Heading>
          <p>
            <FormattedMessage {...messages.description} />
          </p>
        </div>
        {contentData.map((way, idx, ways) => (
          <React.Fragment key={idx}>
            <WayToCollectRow
              cards={way.cards}
              starCount={way.starCount}
              subHeader={way.subHeader}
            />
            {idx < ways.length - 1 && (
              <Rule className="pt3 pb6 md-pt4 md-pb7" weight="thin" />
            )}
          </React.Fragment>
        ))}
      </ContentColumn>
    </div>
  );
};

const select = (state) => ({
  isWaysToPayHash: hashSelector(state) === waysToPayHash,
  isMobileViewport: isMobileViewportSelector(state),
});

export default connect(select)(WaysToPayAndCollectStars);
