import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import H1 from 'shared/app/components/h1';
import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';
import MarketingCard from 'shared/app/components/marketing-card';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { promoUserDataSelector } from 'shared/app/bundles/user/state/selectors';
import { coreAppBaseUrlSelector } from 'shared/app/state/selectors/locales';

import { redeemPromo as redeemPromoAction } from '../../state/action-creators';

import {
  branchScriptSelector,
  branchAppKeySelector,
  branchMatchIdSelector,
  promoErrorCodeSelector,
  promoStatusSelector,
  promoSuccessMessageSelector,
} from '../../state/selectors/promo-codes';

import {
  initializeBranch,
  getUserEligibility,
  getErrorMessageByCode,
  getCaseInsensitivePropertyValue,
} from '../../utils/promo-codes';

import { customNewRelicReport } from 'shared/app/utils/custom-new-relic-report';

import messages from './messages';
import sharedMessages from '../shared/messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

// eslint-disable-next-line max-statements
const PromoCodes = ({
  intl: { formatMessage },
  configImagesUrl,
  branchScript,
  branchAppKey,
  branchMatchId,
  promoUserData,
  redeemPromo,
  promoRedemptionStatus,
  promoErrorCode,
  coreAppBaseUrl,
  promoSuccessMessage,
}) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [branchData, setBranchData] = useState({});
  const [errorMessage, setErrorMessage] = useState('errorGeneric');

  const setUiError = (message) => {
    setIsLoading(false);
    setIsError(true);
    customNewRelicReport('promocode-error', { error: message });
  };
  let promoCodeVal = '';
  if (branchData) {
    promoCodeVal = getCaseInsensitivePropertyValue(branchData, 'promocode');
  }

  useEffect(() => {
    if (promoRedemptionStatus === 'success') {
      setIsLoading(false);
      return;
    }
    if (
      promoRedemptionStatus === 'error' ||
      promoRedemptionStatus === 'failed'
    ) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(getErrorMessageByCode(promoErrorCode));
    }
  }, [promoRedemptionStatus]);

  useEffect(() => {
    if (branchMatchId) {
      initializeBranch({
        branchScript,
        branchAppKey,
        onSuccess: setBranchData,
        onFailure: (err) => {
          setUiError(`Branch failed with error: ${err}`);
        },
      });
    } else {
      setUiError('No branch match ID');
    }
  }, []);

  // eslint-disable-next-line max-statements
  useEffect(() => {
    if (promoUserData.loading || isEmpty(branchData)) {
      return;
    }

    if (promoUserData.error) {
      setUiError('User data fetch error');
      return;
    }

    if (
      promoUserData.userSubMarket &&
      promoUserData.joinDate &&
      !isEmpty(branchData)
    ) {
      if (!getUserEligibility({ promoUserData, branchData })) {
        setUiError(
          `User is not eligible. Eligibility reqs: ${JSON.stringify(
            branchData
          )}`
        );
        return;
      }
      if (!promoCodeVal) {
        setUiError(`No promocode to redeem. Promocode: ${promoCodeVal}`);
        setErrorMessage('errorInvalid');
        return;
      }
      redeemPromo({
        promoCodeString: promoCodeVal,
      });
    }
  }, [branchData, promoUserData]);

  if (isLoading) {
    return <ContentCrateLoading />;
  }
  return (
    <LegacyPaddedContentColumn className="text-center" size="md">
      <ImageLoader
        alt=""
        includeFallback={{
          enabled: true,
          fallbackClassName: 'bg-ceramic',
        }}
        lazyLoad={{ enabled: false }}
        role="presentation"
        src={
          isError
            ? `${configImagesUrl}/promo-codes/failure.png`
            : `${configImagesUrl}/promo-codes/success.png`
        }
        width="232"
        wrapInFlex={{
          enabled: true,
          width: 232,
          height: 232,
          constrainWidth: true,
          flexEmbedWrapperClassName: 'mb5 mx-auto',
        }}
      />
      <H1 className="text-bold pb3" size="lg">
        {isError ? (
          <FormattedMessage {...sharedMessages.errorHeading} />
        ) : (
          <FormattedMessage {...messages.successHeading} />
        )}
      </H1>

      {isError ? (
        <p className="pb2 text-sm">
          <FormattedMessage
            {...messages[errorMessage]}
            values={{ lineBreak: <br /> }}
          />
        </p>
      ) : (
        <React.Fragment>
          <p className="pb2 text-sm">
            {promoSuccessMessage ? (
              promoSuccessMessage
            ) : (
              <FormattedMessage
                {...messages.subHeading}
                values={{
                  accountLink: (
                    <Button
                      className="color-greenStarbucks"
                      href={coreAppBaseUrl}
                      visualStyle="textLink"
                    >
                      <FormattedMessage {...messages.userAccountLink} />
                    </Button>
                  ),
                  placeOrderLink: (
                    <Button
                      className="color-greenStarbucks"
                      href="/menu"
                      visualStyle="textLink"
                    >
                      <FormattedMessage {...messages.placeOrderLink} />
                    </Button>
                  ),
                }}
              />
            )}
          </p>
          <p className="color-textBlackSoft text-sm">
            <FormattedMessage {...messages.successDescription} />
          </p>
        </React.Fragment>
      )}

      <MarketingCard
        bodyMessage={
          isError
            ? formatMessage(messages.errorMarketingCardDescription)
            : formatMessage(messages.successMarketingCardDescription)
        }
        buttonProps={{
          children: formatMessage(sharedCallsToAction.learnMore),
          href: '/rewards/mobile-apps',
          'data-e2e': 'LearnMorePromoCta',
        }}
        className="mt7"
        title={
          isError
            ? formatMessage(messages.errorMarketingCardTitle)
            : formatMessage(messages.successMarketingCardTitle)
        }
        titleHeadingLevel="h2"
      />
    </LegacyPaddedContentColumn>
  );
};

PromoCodes.displayName = PromoCodes;

const select = (state) => ({
  configImagesUrl: configImagesUrlSelector(state),
  branchScript: branchScriptSelector(state),
  branchAppKey: branchAppKeySelector(state),
  branchMatchId: branchMatchIdSelector(state),
  promoUserData: promoUserDataSelector(state),
  promoRedemptionStatus: promoStatusSelector(state),
  promoErrorCode: promoErrorCodeSelector(state),
  promoSuccessMessage: promoSuccessMessageSelector(state),
  coreAppBaseUrl: coreAppBaseUrlSelector(state),
});

export default connect(select, { redeemPromo: redeemPromoAction })(
  injectIntl(PromoCodes)
);
