import { defineMessages } from 'react-intl';

const messages = defineMessages({
  activeHeader: {
    id: 'rewards.optIn.activeHeader',
    description: 'Opt in Active heading text',
    defaultMessage: 'You’re already playing!',
  },
  activeMultiplierHeader: {
    id: 'rewards.optIn.activeMultiplierHeader',
    description: 'Opt in Active for multiplier type heading text',
    defaultMessage: 'You’ve already activated this event!',
  },
  activeExternalBody1: {
    id: 'rewards.optIn.activeExternalBody1',
    description: 'Opt in Active for external type body text 1',
    defaultMessage:
      'Friend! You’ve already started-complete the challenge to collect your Bonus Stars.',
  },
  activeChoiceBody1: {
    id: 'rewards.optIn.activeChoiceBody1',
    description: 'Opt in Active for choice type body text 1',
    defaultMessage:
      'Friend! You’ve already started--check the app to see what you need to order to collect Bonus Stars and complete your challenge.',
  },
  activeMultiplierBody1: {
    id: 'rewards.optIn.activeMultiplierBody1',
    description: 'Opt in Active for multiplier type body text 1',
    defaultMessage: 'Order ahead in the app, online or in one of our cafes.',
  },
  activeBody2: {
    id: 'rewards.optIn.activeBody2',
    description: 'Opt in Active tracking body text 2',
    defaultMessage: 'You can also track your progress in the app.',
  },
  activeMultiplierBody2: {
    id: 'rewards.optIn.activeMultiplierBody2',
    description: 'Opt in Active for multiplier type body text 2',
    defaultMessage: 'See you soon!',
  },
  copyright: {
    id: 'rewards.optIn.copyright',
    description: 'Opt in copyright text',
    defaultMessage:
      '© {currentYear} Starbucks Coffee Company. All rights reserved.',
  },
  disclaimer: {
    id: 'rewards.optIn.disclaimer',
    description: 'Opt in disclaimer text',
    defaultMessage:
      'This offer is exclusive to you and your Starbucks® Rewards account.',
  },
  expiredHeader: {
    id: 'rewards.optIn.expiredHeader',
    description: 'Opt in expired heading text',
    defaultMessage: 'This offer has expired.',
  },
  expiredMultiplierHeader: {
    id: 'rewards.optIn.expiredMultiplierHeader',
    description: 'Opt in multiplier offer expired heading text',
    defaultMessage: 'This event is over.',
  },
  expiredBody1: {
    id: 'rewards.optIn.expiredBody1',
    description: 'Opt in expired body text 1',
    defaultMessage:
      'Looks like we’ve missed each other this time. Keep an eye on your inbox for your next chance to collect Bonus Stars.',
  },
  expiredMultiplierBody1: {
    id: 'rewards.optIn.expiredMultiplierBody1',
    description: 'Opt in multiplier offer expired body text 1',
    defaultMessage:
      'New events are coming soon. Until then, collect Stars by ordering online in the app.',
  },
  expiredBody2: {
    id: 'rewards.optIn.expiredBody2',
    description: 'Opt in expired body text 2',
    defaultMessage:
      'In the meantime, you can collect Stars when you order in the app or on the web.',
  },
  orderAppCta: {
    id: 'rewards.optIn.orderAppCta',
    description: 'Opt in expired Cta text 1',
    defaultMessage: 'Order in the app',
  },
  orderWebCta: {
    id: 'rewards.optIn.orderWebCta',
    description: 'Opt in expired Cta text 2',
    defaultMessage: 'Order on the web',
  },
  errorBody1: {
    id: 'rewards.optIn.errorBody1',
    description: 'Opt in error body text 1',
    defaultMessage:
      'We’re working on this issue, so please try again soon. For immediate help, please visit our {body1Link}.',
  },
  errorBody1LinkText: {
    id: 'rewards.optIn.errorBody1LinkText',
    description: 'Opt in error body text for link',
    defaultMessage: 'customer service page',
  },
  optInSuccessHeader: {
    id: 'rewards.optIn.optInSuccessHeader',
    description: 'Opt in success heading text',
    defaultMessage: 'You’re in!',
  },
  optInSuccessMultiplierHeader: {
    id: 'rewards.optIn.optInSuccessMultiplierHeader',
    description: 'Opt in success multiplier type heading text',
    defaultMessage: 'You’re ready to go!',
  },
  optInSuccessBody1: {
    id: 'rewards.optIn.optInSuccessBody1',
    description: 'Opt in success body text 1',
    defaultMessage:
      'You’re in the challenge and on your way to collecting Bonus Stars. Track your progress in the app.',
  },
  optInSuccessMultiplierBody1: {
    id: 'rewards.optIn.optInSuccessMultiplierBody1',
    description: 'Opt in success multiplier type body text 1',
    defaultMessage:
      'You’re on your way to earning more Rewards. Come on in or order ahead and pay in the app. See you soon!',
  },
  optInSuccessBody2: {
    id: 'rewards.optIn.optInSuccessBody2',
    description: 'Opt in success body text 2',
    defaultMessage:
      'By the way—mobile orders count toward completing this challenge!',
  },
  privacyStatement: {
    id: 'rewards.optIn.privacyStatement',
    description: 'Opt in privacy statement text',
    defaultMessage: 'Privacy Statement',
  },
  rewardsTerms: {
    id: 'rewards.optIn.rewardsTerms',
    description: 'Opt in rewards terms of use text',
    defaultMessage: 'Starbucks® Rewards Terms of Use',
  },
  svcTerms: {
    id: 'rewards.optIn.svcTerms',
    description: 'Opt in starbucks card terms and conditions text',
    defaultMessage: 'Starbucks Card Terms of Use',
  },
});

export default messages;
