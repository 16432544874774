import React from 'react';
import { useIntl } from 'react-intl';

import { rewardsMessages } from 'shared/app/messages/rewards';

import MarriottResultPage from '.';

import messages from './messages';

const LinkSuccess = () => {
  const { formatMessage } = useIntl();
  return (
    <MarriottResultPage
      body={formatMessage(messages.successBody)}
      ctaHref="/rewards/partnerships/marriottbonvoy#termsPerksReference1"
      ctaText={formatMessage(messages.seeBenefitsCta)}
      heading={formatMessage(messages.successHeader)}
      imageAlt={formatMessage(rewardsMessages.rewardsPartnershipsMarriott)}
      imagePath="rewards/loyalty-partnerships/marriott-bonvoy/success.png"
    />
  );
};

export default LinkSuccess;
