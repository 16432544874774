import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';

import H1 from 'shared/app/components/h1';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

const MarriottBanner = ({
  body,
  BodyTagName = 'p',
  columnClassName = 'px0',
  ctaHref,
  ctaOnClick,
  ctaTagName = Button,
  ctaText,
  heading,
  HeadingTagName = H1,
  imageAlt = '',
  imagePath,
}) => {
  const BodyTag = BodyTagName === 'h1' ? H1 : BodyTagName;
  const CtaTag = ctaTagName;
  const configImagesUrl = useSelector(configImagesUrlSelector);
  return (
    <ContentColumn
      className={columnClassName}
      innerClassName="md-flex"
      size="xl"
    >
      <div className="md-size6of12 flex justify-center items-center bg-neutralWarm py7 text-center">
        <ContentColumn size="md">
          <HeadingTagName className="text-bold text-xl mb3">
            {heading}
          </HeadingTagName>
          <BodyTag className="text-sm mb5">{body}</BodyTag>
          <CtaTag href={ctaHref} onClick={ctaOnClick} visualStyle="positive">
            {ctaText}
          </CtaTag>
        </ContentColumn>
      </div>
      <div className="md-size6of12 flex justify-center items-center bg-greenLight">
        <ImageLoader
          alt={imageAlt}
          height="624px"
          includeFallback={{
            enabled: true,
            fallbackClassName: 'bg-greenLight',
          }}
          lazyLoad={{
            enabled: false,
          }}
          src={`${configImagesUrl}/${imagePath}`}
          width="720px"
          wrapInFlex={{
            enabled: true,
            width: 720,
            height: 624,
            flexEmbedWrapperClassName: 'width-100',
            constrainWidth: true,
          }}
        />
      </div>
    </ContentColumn>
  );
};

export default MarriottBanner;
