import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { imageUrl } from '../../../utils';

import StarIcon from '../../shared/star-icon';

const first = {
  starCount: 1,
  subHeader: <FormattedMessage {...messages.payAsYouGo} />,
  cards: [
    {
      imageUrl: `${imageUrl}/rewards/ways-to-pay/1A.png`,
      imageAlt: '',
      heading: (
        <FormattedMessage
          {...messages.waysToCollect1Heading1}
          values={{ starIcon: <StarIcon /> }}
        />
      ),
      description: (
        <FormattedMessage {...messages.waysToCollect1Description1} />
      ),
    },
    {
      imageUrl: `${imageUrl}/rewards/ways-to-pay/1B.png`,
      imageAlt: '',
      heading: (
        <FormattedMessage
          {...messages.waysToCollect1Heading2}
          values={{ starIcon: <StarIcon /> }}
        />
      ),
      description: (
        <FormattedMessage {...messages.waysToCollect1Description2} />
      ),
    },
  ],
};

const second = {
  starCount: 2,
  subHeader: <FormattedMessage {...messages.addFunds} />,
  cards: [
    {
      imageUrl: `${imageUrl}/rewards/ways-to-pay/2A.png`,
      imageAlt: '',
      heading: (
        <FormattedMessage
          {...messages.waysToCollect2Heading1}
          values={{ starIcon: <StarIcon /> }}
        />
      ),
      description: (
        <FormattedMessage
          {...messages.waysToCollect2Description1}
          values={{
            newLine: (
              <React.Fragment>
                <br />
                <br />
              </React.Fragment>
            ),
          }}
        />
      ),
    },
    {
      imageUrl: `${imageUrl}/rewards/ways-to-pay/2B.png`,
      imageAlt: '',
      heading: (
        <FormattedMessage
          {...messages.waysToCollect2Heading2}
          values={{ starIcon: <StarIcon /> }}
        />
      ),
      description: (
        <FormattedMessage
          {...messages.waysToCollect2Description2}
          values={{ starIcon: <StarIcon /> }}
        />
      ),
    },
  ],
};

export { first, second };
