import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Tabs, {
  TabList,
  Tab,
  TabPanelList,
  TabPanel,
} from '@starbucks-web/pattern-library/lib/components/tabs';

import { commonMessages } from 'shared/app/messages';

import { configSelector } from '../../../state/selectors';
import StarIcon from '../../shared/star-icon';

import TabContent from './tab-content';
import messages from './messages';
import getTabData from './tab-data';
import styles from './styles.cssm';

const generateTabs = (data, starLevels) => {
  return data.map((tab, idx) => (
    <Tab key={`reward-tiers-tab-${idx}`}>
      <span className="text-md text-semibold flex-grow">
        {`${starLevels[idx]}`}
        <StarIcon textSizeClassName="text-xs" />{' '}
        <span className="hiddenVisually">
          <FormattedMessage {...commonMessages.stars} />
        </span>
      </span>
    </Tab>
  ));
};

const generateTabPanels = (data) => {
  return data.map((tab, idx) => (
    <TabPanel key={`reward-tiers-tab-panel-${idx}`}>
      <TabContent
        body={<FormattedMessage {...tab.tabContentBodyMessage} />}
        header={<FormattedMessage {...tab.tabContentHeaderMessage} />}
        imageAlt=""
        imageUrl={tab.imageUrl}
      />
    </TabPanel>
  ));
};

const RewardTiers = ({
  intl: { formatMessage },
  locale,
  rewardsProgramStarLevels,
}) => {
  const tabData = getTabData(locale);

  return (
    <div className="sb-global-gutters pt7 px0 text-center bg-greenLightSecondary">
      <div className="px3">
        <Heading className="text-semibold pb3 md-pb7" size="lg" tagName="h2">
          <FormattedMessage {...messages.header} />
        </Heading>
      </div>
      <Tabs>
        <TabList
          aria-label={formatMessage(messages.tabListAriaLabel)}
          className={`size12of12 mx-auto ${styles.tabList}`}
        >
          {generateTabs(tabData, rewardsProgramStarLevels)}
        </TabList>
        <TabPanelList className="bg-greenLight pb7 md-pb0">
          {generateTabPanels(tabData)}
        </TabPanelList>
      </Tabs>
    </div>
  );
};

const select = (state) => ({
  locale: state.locale,
  rewardsProgramStarLevels: configSelector(state)?.rewardsProgramStarLevels,
});

export default connect(select)(injectIntl(RewardTiers));
