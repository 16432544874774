import React from 'react';
import { FormattedMessage } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';

import { imageUrl } from 'rewards/app/utils';
import { marriottTermsDescriptionId } from 'rewards/app/components/rewards-page/terms/terms-us';
import styles from 'rewards/app/components/shared/styles.cssm';

import messages from './messages';

const MARRIOTT_HREF = '/rewards/partnerships/marriottbonvoy';

const marriott = () => {
  return {
    imageAlt: 'Marriott Bonvoy®',
    imageUrl: `${imageUrl}/rewards/loyalty-partnerships/marriott-bonvoy/marriott-logo.png`,
    imageWidth: '190px',
    description: (
      <FormattedMessage
        {...messages.marriottProgramDescription}
        values={{
          inlineLink: (content) => (
            <ExternalLink href={MARRIOTT_HREF}>{content}</ExternalLink>
          ),
          marriottTerms: (
            <a
              aria-labelledby="disclaimerTitleId"
              className={`text-noUnderline ${styles.target}`}
              href={`#${marriottTermsDescriptionId}`}
              id="marriottNoteId"
            >
              <sup>3</sup>
            </a>
          ),
        }}
      />
    ),
  };
};

marriott.displayName = 'marriott';

export default marriott;
