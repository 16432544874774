import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

const ArrowLink = ({ href }) => {
  const { formatMessage } = useIntl();
  return (
    <a
      aria-label={formatMessage(messages.disclaimerBackToContent)}
      className="text-noUnderline"
      href={href}
    >
      ↩
    </a>
  );
};

export default ArrowLink;
