import { imageUrl } from '../../../../utils';

import { defineMessages } from 'react-intl';

const getToFreeFasterMessages = defineMessages({
  headingContent1: {
    id: 'rewards.benefits.slides.getToFreeFaster.1.headingContent',
    description: 'get to free faster slide 1 heading',
    defaultMessage: 'Bonus Star challenges',
  },
  bodyContent1: {
    id: 'rewards.benefits.slides.getToFreeFaster.1.bodyContent',
    description: 'get to free faster slide 1 body content',
    defaultMessage:
      'Rack up the Stars with regular opportunities to get rewarded for what you love.',
  },
  headingContent2: {
    id: 'rewards.benefits.slides.getToFreeFaster.2.headingContent',
    description: 'get to free faster slide 2 heading',
    defaultMessage: 'Double Star Days',
  },
  bodyContent2: {
    id: 'rewards.benefits.slides.getToFreeFaster.2.bodyContent',
    description: 'get to free faster slide 2 body content',
    defaultMessage:
      'Watch for those special days where you’ll earn twice the Stars on almost everything.',
  },
  headingContent3: {
    id: 'rewards.benefits.slides.getToFreeFaster.3.headingContent',
    description: 'get to free faster slide 3 heading',
    defaultMessage: 'Member-only games',
  },
  bodyContent3: {
    id: 'rewards.benefits.slides.getToFreeFaster.3.bodyContent',
    description: 'get to free faster slide 3 body content',
    defaultMessage:
      'Play for a chance to win exclusive prizes, free food and drinks, and more.',
  },
});

export const getGetToFreeFasterSlideData = (formatMessage) => [
  {
    imageUrl: `${imageUrl}/rewards/benefits/3A.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(getToFreeFasterMessages.headingContent1),
    bodyContent: formatMessage(getToFreeFasterMessages.bodyContent1),
  },
  {
    imageUrl: `${imageUrl}/rewards/benefits/3B.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(getToFreeFasterMessages.headingContent2),
    bodyContent: formatMessage(getToFreeFasterMessages.bodyContent2),
  },
  {
    imageUrl: `${imageUrl}/rewards/benefits/3C.jpg`,
    imageLoaderProps: {
      role: 'presentation',
      alt: '',
    },
    headingContent: formatMessage(getToFreeFasterMessages.headingContent3),
    bodyContent: formatMessage(getToFreeFasterMessages.bodyContent3),
  },
];
