/* global branch */
const getCaseInsensitivePropertyValue = (dataObj, keyToFind) => {
  if (!keyToFind || !dataObj) return;
  const objKey = Object.keys(dataObj).find(
    (key) => key.toLowerCase() === keyToFind.toLowerCase()
  );
  return dataObj[objKey];
};

const initializeBranch = ({
  branchScript,
  branchAppKey,
  onSuccess,
  onFailure,
}) => {
  const injectScript = () => {
    const script = document.createElement('script');
    script.innerHTML = branchScript;
    document.body.appendChild(script);
  };
  injectScript();

  if (branch) {
    branch.init(branchAppKey, (err, data) => {
      if (err) {
        return onFailure(err);
      }
      return onSuccess(data.data_parsed);
    });
  } else {
    return onFailure('Branch script failed to inject into the browser');
  }
};

const getUserEligibility = ({ promoUserData, branchData }) => {
  const { joinDate, userSubMarket } = promoUserData;
  const marketVal = getCaseInsensitivePropertyValue(branchData, 'market');
  const eligibilityDateVal = getCaseInsensitivePropertyValue(
    branchData,
    'eligibilitydate'
  );
  const eligNotValidDate =
    Boolean(eligibilityDateVal) && isNaN(Date.parse(eligibilityDateVal));
  const joinedBeforeEligDate =
    Boolean(eligibilityDateVal) &&
    Date.parse(eligibilityDateVal) > Date.parse(joinDate);
  const notInUsersMarket = Boolean(marketVal) && marketVal !== userSubMarket;

  if (eligNotValidDate || joinedBeforeEligDate || notInUsersMarket) {
    return false;
  }
  return true;
};

const getErrorMessageByCode = (code) => {
  switch (code) {
    case 1008:
    case 1010:
    case 1100:
      return 'errorGeneric';

    case 1002:
    case 1009:
      return 'errorInvalid';

    case 1003:
    case 1004:
    case 1005:
    case 1007:
      return 'errorExpired';

    case 1006:
      return 'errorTryAgain';

    default:
      return 'errorGeneric';
  }
};

module.exports = {
  getCaseInsensitivePropertyValue,
  initializeBranch,
  getUserEligibility,
  getErrorMessageByCode,
};
